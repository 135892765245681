export default () => (
  <svg
    x="0px"
    y="0px"
    width="400px"
    height="400px"
    viewBox="0 0 375 400"
    className="sedan"
  >
    <g>
      <path
        className="body"
        d="M138.236,313l-18.382,27h138.63l-18.39-27H138.236z M126.228,337l13.839-21h98.196l13.845,21H126.228z"
      />
      <g>
        <g className="secondRow" id="SECOND_ROW_MIDDLE">
          <path
            d="M203.445,287.27c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
			c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
			c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
			c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C203.636,289.173,203.445,287.27,203.445,287.27z"
          />
          <g>
            <path
              d="M197.872,264h-17.516c0.011,2,0.012,10.017-1.896,18.874l7.87,0.126h13.308c0.101,0,0.201-0.108,0.302-0.101
          C197.848,273.366,197.871,264,197.872,264z"
            />
          </g>
          <path
            d="M174.417,285.274c0.462-0.938,1.429-1.758,2.546-2.145c1.97-8.957,1.968-17.129,1.957-19.129h-1.726h-1.701h-1.857H170v29
        h5.486c-1.815-4-1.501-6.135-1.501-6.135C174.059,285.852,174.412,285.281,174.417,285.274z"
          />
          <path
            d="M209,264h-4.346h-0.568h-1.289h-3.427c-0.011,2-0.013,10.171,1.957,19.129c1.117,0.387,2.084,1.183,2.546,2.121
			c0.005,0.007,0.358,0.591,0.432,1.604c0,0,0.314,2.147-1.501,6.147h3.428h2.561H209V264z"
          />
          <rect x="170" y="220" width="39" height="43" />
        </g>
        <g className="secondRow" id="SECOND_ROW_DRIVER_SIDE">
          <path
            d="M161.596,287.27c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
				c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
				c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
				c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C161.787,289.173,161.596,287.27,161.596,287.27z"
          />
          <path
            d="M124.04,257.155c0,0,0.383-0.275,2.196,0.613c1.698,0.715,5.124,3.233,7.409,6.233h3.427
				c0.011,2,0.013,10.171-1.957,19.129c-1.117,0.387-2.084,1.183-2.546,2.121c-0.005,0.007-0.358,0.658-0.432,1.671
				c-0.009,0.065-0.248,1.698,1.045,4.725c0.242,0.621,0.536,0.354,0.87,1.354h-6.403c-3.732,0-6.648-2.325-7.648-5.849v-0.839
				c0-0.004,0-0.009,0-0.013c0-0.004,0-0.009,0-0.013v-16.014v-6.094v-2.154C120,262.023,120.181,257.826,124.04,257.155z"
          />
          <path
            d="M156.023,264h-17.516c0.011,2,0.012,10.017-1.896,18.874l7.87,0.126h13.308c0.101,0,0.201-0.108,0.302-0.101
				C156,273.366,156.022,264,156.023,264z"
          />
          <path
            d="M168,264h-4.798h-2.342h-1.6h-0.854h-0.973c-0.011,2-0.013,10.171,1.957,19.129c1.117,0.387,2.084,1.183,2.546,2.121
				c0.005,0.007,0.358,0.658,0.432,1.671c0.009,0.065,0.248,1.698-1.045,4.725c-0.242,0.621-0.128,0.354-0.462,1.354h4.377h0.583
				H168V264z"
          />
          <path
            d="M168,220h-4.256h-0.472h-0.238h-1.602h-13.276h-1.39h-15.299h-1.247h-0.432c-4.112,0-5.451,4.43-5.451,4.43
				c-4.721,15.104-0.285,28.871,1.091,32.599l0.072-0.222c0.189,0.055,5.501,2.193,8.706,6.193h0.797h1.317h1.437h9.01h1.39h7.015
				h1.437h0.562h2.157H168V220z"
          />
        </g>
        <g className="secondRow" id="SECOND_ROW_PASSENGER_SIDE">
          <path
            d="M216.705,287.27c0.066-0.907,0.256-1.458,0.256-1.458c0.606-1.231,1.706-2.078,3.172-2.078l22.184,0.035
				c1.466,0,2.465,0.83,3.071,2.061c0,0,0.185,0.542,0.251,1.449c0,0,0.189,1.899-1.033,4.756c0,0-1.418,4.018-4.505,4.98
				c0,0-3.399,0.985-8.647,0.988c-0.092,0.001-0.179-0.001-0.269,0c-0.09,0-0.177,0.001-0.268-0.001
				c-5.274-0.003-8.659-0.989-8.659-0.989c-3.087-0.961-4.523-4.982-4.523-4.982C216.514,289.173,216.705,287.27,216.705,287.27z"
          />
          <path
            d="M254.111,257.155c0,0-0.308-0.275-2.121,0.613c-1.698,0.715-5.048,3.233-7.333,6.233h-3.427
				c-0.011,2-0.013,10.171,1.957,19.129c1.117,0.387,2.084,1.183,2.546,2.121c0.005,0.007,0.358,0.658,0.432,1.671
				c0.009,0.065,0.248,1.698-1.045,4.725c-0.242,0.621-0.536,0.354-0.87,1.354h6.403c3.732,0,6.347-2.325,7.347-5.849v-0.839
				c0-0.004,0-0.009,0-0.013c0-0.004,0-0.009,0-0.013v-16.014v-6.094v-2.154C258,262.023,257.97,257.826,254.111,257.155z"
          />
          <path
            d="M222.278,264h17.516c-0.011,2-0.012,10.017,1.896,18.874L233.82,283h-13.308c-0.101,0-0.201-0.108-0.302-0.101
				C222.302,273.366,222.279,264,222.278,264z"
          />
          <path
            d="M211,264h4.1h2.342h1.6h0.854h0.973c0.011,2,0.013,10.171-1.957,19.129c-1.117,0.387-2.084,1.183-2.546,2.121
				c-0.005,0.007-0.358,0.658-0.432,1.671c-0.009,0.065-0.248,1.698,1.045,4.725c0.242,0.621,0.128,0.354,0.462,1.354h-4.377h-0.583
				H211V264z"
          />
          <path
            d="M211,220h3.557h0.472h0.238h1.602h13.276h1.39h15.299h1.247h0.432c4.112,0,5.451,4.43,5.451,4.43
				c4.721,15.104,0.285,28.871-1.091,32.599l-0.072-0.222c-0.189,0.055-5.501,2.193-8.706,6.193h-0.797h-1.317h-1.437h-9.01h-1.39
				h-7.015h-1.437h-0.562h-2.157H211V220z"
          />
        </g>
      </g>
      <g>
        <path
          className="body"
          d="M260.739,112.519c-20.453-10.345-44.973-15.812-70.909-15.812c-26.747,0-52.823,6.072-73.422,17.097l-1.131,0.605
			l0.439,1.204c4.94,13.568,13.9,37.85,18.415,48.03l0.498,1.124l1.196-0.285c17.345-4.133,36.946-6.318,56.682-6.318
			c17.005,0,33.469,1.56,48.936,4.636l1.14,0.227l0.489-1.054c4.15-8.951,13.303-33.682,18.39-47.627l0.45-1.233L260.739,112.519z
			 M258.23,114.572c-4.756,13.018-13.154,35.683-17.362,45.103c-15.32-2.965-31.589-4.468-48.361-4.468
			c-19.492,0-38.903,2.114-56.163,6.117c-4.514-10.445-12.769-32.791-17.408-45.512c19.988-10.415,45.148-16.148,70.894-16.148
			C214.791,99.664,238.433,104.818,258.23,114.572z"
        />
        <g>
          <g id="DRIVER">
            <path
              d="M125.798,165.576c0.189,0.055,5.501,2.424,8.706,6.424h0.797h1.317h1.436h9.01h8.405h1.437h0.562h2.157
					c3.205-4,8.516-6.502,8.706-6.556l0.071-0.094c1.376-3.729,5.812-17.711,1.091-32.814c0,0-1.339-4.535-5.451-4.535h-0.472
					h-0.238h-1.602h-14.666h-15.299H130v0.418c0,0,0-0.418,0-0.418h0.086c-4.112,0-5.451,4.738-5.451,4.738
					c-4.721,15.104-0.285,29.06,1.091,32.788L125.798,165.576z"
            />
            <g>
              <path
                d="M161.169,196.741c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
						c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
						c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
						c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C161.36,198.644,161.169,196.741,161.169,196.741z"
              />
              <path
                d="M160.41,203h6.018c3.732,0,6.572-2.854,7.572-6.378v-0.839c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094
						v-2.154c0,0-0.142-4.197-4.001-4.868c0,0-0.364-0.01-2.177,0.877c-1.698,0.715-5.104,2.497-7.389,6.497h-3.427
						c-0.011,1-0.013,9.907,1.957,18.864c1.117,0.387,2.084,1.051,2.546,1.988c0.005,0.007,0.358,0.592,0.432,1.605
						c0.007,0.055,0.183,1.469-0.571,3.716L160.41,203z"
              />
              <path
                d="M133.081,203h-5.859c-3.732,0-7.222-2.854-8.222-6.378v-0.839c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094
						v-2.154c0,0,0.468-4.197,4.327-4.868c0,0,0.527-0.01,2.339,0.877c1.698,0.715,5.267,2.497,7.552,6.497h3.427
						c0.011,1,0.013,9.907-1.957,18.864c-1.117,0.387-2.084,1.051-2.546,1.988c-0.005,0.007-0.358,0.789-0.432,1.802
						C131.709,196.655,131.506,200,133.081,203z"
              />
              <g>
                <path
                  d="M155.596,174h-17.516c0.011,1,0.012,9.517-1.896,18.374l7.87-0.374h13.308c0.101,0,0.201,0.392,0.302,0.399
							C155.573,182.866,155.595,174,155.596,174z"
                />
              </g>
            </g>
          </g>
          <g id="PASSENGER">
            <path
              d="M209.495,165.576c0.189,0.055,5.501,2.424,8.706,6.424h0.797h1.317h1.436h9.01h8.405h1.437h0.562h2.157
					c3.205-4,8.516-6.502,8.706-6.556l0.071-0.094c1.376-3.729,5.812-17.711,1.091-32.814c0,0-1.339-4.535-5.451-4.535h-0.472
					h-0.238h-1.602h-14.666h-15.299H214v0.418c0,0,0-0.418,0-0.418h-0.217c-4.112,0-5.451,4.738-5.451,4.738
					c-4.721,15.104-0.285,29.06,1.091,32.788L209.495,165.576z"
            />
            <g>
              <path
                d="M244.866,196.741c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
						c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
						c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
						c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C245.057,198.644,244.866,196.741,244.866,196.741z"
              />
              <path
                d="M244.107,203h6.018c3.732,0,6.875-2.854,6.875-6.378v-0.839c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094
						v-2.154c0,0,0.206-4.197-3.653-4.868c0,0-0.19-0.01-2.002,0.877c-1.698,0.715-4.93,2.497-7.215,6.497h-3.427
						c-0.011,1-0.013,9.907,1.957,18.864c1.117,0.387,2.084,1.051,2.546,1.988c0.005,0.007,0.358,0.592,0.432,1.605
						c0.007,0.055,0.183,1.469-0.571,3.716L244.107,203z"
              />
              <path
                d="M216.778,203h-5.859c-3.732,0-6.919-2.854-7.919-6.378v-0.839c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094
						v-2.154c0,0,0.316-4.197,4.175-4.868c0,0,0.451-0.01,2.263,0.877c1.698,0.715,5.191,2.497,7.476,6.497h3.427
						c0.011,1,0.013,9.907-1.957,18.864c-1.117,0.387-2.084,1.051-2.546,1.988c-0.005,0.007-0.358,0.789-0.432,1.802
						C215.406,196.655,215.203,200,216.778,203z"
              />
              <g>
                <path
                  d="M239.293,174h-17.516c0.011,1,0.012,9.517-1.896,18.374l7.87-0.374h13.308c0.101,0,0.201,0.392,0.302,0.399
							C239.27,182.866,239.292,174,239.293,174z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className="body"
        d="M274,106.036V74.151C274,51.246,254.77,25,230.738,25h-85.412C121.28,25,103,51.247,103,74.152v31.854l-30,9.964v11.204
		l30-8.849v208.627C103,350.5,121.783,370,145.326,370h85.412C254.285,370,274,350.5,274,326.953V118.338l30,8.836v-11.205
		L274,106.036z M300,122.602l-25.292-7.46L270,113.818v3.511v209.625C270,348.616,252.401,367,230.738,367h-85.412
		C123.667,367,106,348.616,106,326.953V114.639v-0.899c-1,0.26-2,0.647-3,1.017v0.006l-27,7.838v-4.169l27-8.871v0.016l3-1.133
		v-0.638V74.152c0-20.716,16.217-44.696,37.421-46.049c-0.759,6.495-4.629,29.62-23.295,61.765
		c-0.474,0.816-0.191,1.861,0.625,2.334c0.27,0.157,0.568,0.231,0.859,0.231c0.589,0,1.163-0.323,1.48-0.869
		C142.678,57.832,146.267,34,146.901,28h81.602c0.634,6,4.221,29.832,23.81,63.564c0.317,0.546,0.89,0.86,1.479,0.86
		c0.291,0,0.586-0.07,0.856-0.226c0.816-0.474,1.093-1.516,0.619-2.332c-18.69-32.186-22.57-55.327-23.322-61.787
		C253.46,28.97,270,53.222,270,74.152v30.749v3.601v0.009l4,1.13v-0.005l3.21,1.116l-0.071,0.001l-0.062,0.021l0,0L300,118.434
		V122.602z"
      />
    </g>
  </svg>
);
