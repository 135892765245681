import { twMerge } from 'tailwind-merge';

import { Spinner } from '@assured/design-system/src/components/Spinner';

const OutOfOfficeSettingsFooter = ({
  onCancel,
  submitDisabled,
  submitLoading,
}: {
  onCancel: () => void;
  submitDisabled: boolean;
  submitLoading: boolean;
}) => {
  return (
    <footer className="flex justify-end items-center min-h-[90px] border-t px-4">
      <button
        className="border rounded-lg min-h-[40px] inline-flex items-center px-6 mr-6 text-gray-600 outline:none focus-visible:ring-1 transition-colors hover:bg-gray-50"
        type="button"
        onClick={onCancel}
      >
        Cancel
      </button>
      <button
        className={twMerge(
          'rounded-lg min-h-[40px] inline-flex items-center px-6 text-white bg-indigo-bright-600 hover:bg-indigo-700 disabled:bg-gray-400',
          submitLoading && 'bg-indigo-500',
        )}
        disabled={submitDisabled}
        type="submit"
      >
        {submitLoading && (
          <span className="pr-1">
            <Spinner className="text-white" />
          </span>
        )}
        Save
      </button>
    </footer>
  );
};

export default OutOfOfficeSettingsFooter;
