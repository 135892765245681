import React from 'react';

import { OutOfOfficeSettingsModalContext } from './OutOfOfficeSettingsContext';

const OutOfOfficeIgnoredProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const value = React.useMemo(
    () => ({
      getFloatingProps: () => ({}),
      getReferenceProps: () => ({}),
      setIsOpen: () => {},
      floatingStyles: {},
      outOfOfficeActive: false,
      refs: {
        setFloating: () => {},
        setReference: () => {},
        setPositionReference: () => {},
        reference: React.createRef<null>(),
        floating: React.createRef<null>(),
        domReference: React.createRef<null>(),
      },
      adjusterOutOfOfficeSettings: undefined,
    }),
    [],
  );
  return (
    <OutOfOfficeSettingsModalContext.Provider value={value}>
      {children}
    </OutOfOfficeSettingsModalContext.Provider>
  );
};

export default OutOfOfficeIgnoredProvider;
