import { InlineComponentType, WidgetType } from '@assured/step-renderer';

import GraphicComponent from '../components/ClaimWorkflow/inline/Graphic';
import PolicyInfoComponent from '../components/ClaimWorkflow/inline/Policy';
import { InlineFC } from '../components/ClaimWorkflow/types/inlineComponentTypes';
import { WidgetFC } from '../components/ClaimWorkflow/types/widgetComponentTypes';
import ConfettiComponent from '../components/ClaimWorkflow/widgets/Confetti';
import MapComponent from '../components/ClaimWorkflow/widgets/Map';
import VehicleSeatmapWidgetComponent from '../components/ClaimWorkflow/widgets/Seatmap';
import WeatherComponent from '../components/ClaimWorkflow/widgets/Weather';

const WIDGET_COMPONENTS: Record<WidgetType, WidgetFC<any>> = {
  weather: WeatherComponent,
  vehicle_seatmap: VehicleSeatmapWidgetComponent,
  confetti: ConfettiComponent,
  map: MapComponent,
};

const INLINE_COMPONENTS: Record<InlineComponentType, InlineFC<any>> = {
  policyInfo: PolicyInfoComponent,
  graphic: GraphicComponent,
};

export function useWidgetLookup(type: WidgetType) {
  return WIDGET_COMPONENTS[type];
}

export function useInlineComponentLookup(type: InlineComponentType) {
  return INLINE_COMPONENTS[type];
}
