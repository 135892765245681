import { useLocalStorage } from 'react-use';

import {
  AdminCaseFilter,
  AdminCaseFilterMode,
} from '../components/CaseDashboard/types';

export interface Settings {
  godMode?: boolean;

  // Case dashboard
  caseDashboardFilter?: AdminCaseFilter;
  caseDashboardFilterMode?: AdminCaseFilterMode;
  caseDashboardFilterUser?: string;

  /**
   * Used for internal (Assured staff) users to bypass the Auth0 connection restriction
   * in apps/adjuster/src/components/Providers/AssuredAuthOProvider.tsx
   */
  isInternalUser?: boolean;
}

export default function useLocalSettings() {
  const [settings, setSettings] = useLocalStorage<Settings>(
    'assured-settings',
    {},
  );

  return {
    settings,
    updateSettings: (newSettings: Partial<Settings>) => {
      setSettings(s => ({ ...s, ...newSettings }));
    },
  };
}
