import React from 'react';

export default () => (
  <svg width="400" height="400" viewBox="0 0 171 303">
    <g opacity="0.15">
      <path
        d="M166.631 123.057C166.569 122.809 166.507 122.5 166.446 122.252C164.464 115.068 153.997 110.361 149.353 108.565L149.105 57.3483C149.105 57.3483 148.919 30.0368 143.16 22.0477C137.462 14.0586 118.016 5.4502 118.016 5.4502L85.5019 4.33545L52.9882 5.51214C52.9882 5.51214 33.5419 14.1205 27.8442 22.1096C22.1466 30.0987 21.8988 57.4102 21.8988 57.4102L21.6511 108.627C17.0063 110.423 6.60192 115.13 4.5582 122.314C4.49627 122.561 4.43434 122.871 4.37241 123.119C4.18662 124.048 4.92979 124.853 5.85875 124.791C9.14109 124.605 16.0773 122.004 18.9881 120.889C19.7932 120.58 20.2886 119.775 20.2267 118.969L20.1648 117.669L19.979 113.829L21.5892 112.9L21.4653 145.166L21.8988 203.877L20.908 257.633L31.9317 282.405L34.7805 284.015L36.6384 288.722C36.6384 288.722 62.2159 297.702 84.8826 297.702H86.1212C108.85 297.702 134.365 288.722 134.365 288.722L136.223 284.015L139.072 282.405L150.096 257.633L149.043 203.815L149.476 145.104L149.353 112.838L150.963 113.767L150.777 117.607L150.715 118.908C150.653 119.775 151.211 120.518 151.954 120.827C154.864 121.942 161.739 124.543 165.083 124.729C166.074 124.853 166.817 123.986 166.631 123.057Z"
        fill="#FAFAF8"
      />
      <path
        d="M84.8807 302.037C61.7185 302.037 36.265 293.181 35.1502 292.809L33.2923 292.128L31.3105 286.988L28.5236 285.377L16.509 258.438L17.5618 203.753L17.1283 145.104L17.1903 126.153C13.2267 127.578 8.89152 128.94 6.10463 129.064C5.98077 129.064 5.85691 129.064 5.79497 129.064C4.06091 129.064 2.45071 128.321 1.33595 126.958C0.221196 125.658 -0.21232 123.924 0.0973344 122.252C0.159265 121.88 0.283127 121.446 0.406989 121.013C2.51264 113.519 10.9972 108.503 17.376 105.716L17.6238 57.348C17.6857 50.4737 18.1811 28.2405 24.3742 19.5702C30.5673 10.8998 49.1466 2.47723 51.2522 1.54827L51.9954 1.23862L85.5 0L119.005 1.23862L119.748 1.54827C121.853 2.47723 140.433 10.8379 146.688 19.5702C152.881 28.2405 153.376 50.4737 153.438 57.348L153.686 105.716C160.003 108.503 168.487 113.519 170.593 121.137C170.717 121.57 170.779 121.942 170.903 122.313C171.212 123.986 170.779 125.72 169.664 127.082C168.549 128.383 166.939 129.188 165.205 129.188C165.081 129.188 164.957 129.188 164.895 129.188C162.108 129.002 157.773 127.701 153.81 126.277L153.872 145.166L153.438 203.876L154.491 258.561L142.476 285.439L139.69 287.05L137.708 292.19L135.85 292.871C134.797 293.243 109.343 302.099 86.1193 302.099H84.8807V302.037Z"
        fill="#1E1E20"
      />
      <path
        d="M86.1212 297.702C108.85 297.702 134.365 288.722 134.365 288.722L136.223 284.015L139.072 282.405L150.096 257.633L149.043 203.815L149.476 145.104L149.043 57.3483C149.043 57.3483 148.857 30.0368 143.098 22.0477C137.4 14.0586 117.954 5.4502 117.954 5.4502L85.5019 4.33545L52.9882 5.51214C52.9882 5.51214 33.5419 14.1205 27.8442 22.1096C22.1466 30.0987 21.8989 57.4102 21.8989 57.4102L23.0755 142.751L24.438 187.775L21.9608 192.667L21.8989 203.877L20.908 257.633L31.9317 282.405L34.7805 284.015L36.6384 288.722C36.6384 288.722 62.2159 297.702 84.8826 297.702"
        fill="url(#paint0_linear)"
      />
      <path
        d="M85.5015 285.625C68.037 285.563 58.1899 284.325 53.9786 283.643C52.9878 283.458 52.2446 284.51 52.6781 285.439L53.4213 286.926C54.4741 289.031 56.4559 290.518 58.8093 290.889C63.516 291.632 72.5579 292.747 85.5634 292.871C98.5689 292.809 107.611 291.694 112.318 290.889C114.609 290.518 116.653 289.031 117.706 286.926L118.449 285.439C118.882 284.51 118.139 283.519 117.148 283.643C112.875 284.325 103.028 285.563 85.5015 285.625Z"
        stroke="url(#paint1_linear)"
        stroke-width="0.575848"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.56"
        d="M41.7779 77.8474C41.6541 51.341 45.6176 36.8492 53.1113 9.66152C53.359 8.85642 52.3681 8.17518 51.6869 8.73255L36.4519 21.0568L31.5593 83.1115L32.6121 93.7636C32.5502 93.7636 38.6814 91.3483 41.7779 77.8474Z"
        fill="url(#paint2_linear)"
      />
      <path
        opacity="0.56"
        d="M129.286 77.8472C129.41 50.4738 125.199 35.1149 117.21 6.99829L134.612 21.0566L139.505 83.1113L138.452 93.7634C138.514 93.7634 132.383 91.3481 129.286 77.8472Z"
        fill="url(#paint3_linear)"
      />
      <path
        opacity="0.56"
        d="M54.9698 290.518C54.9698 290.518 33.9752 261.039 29.5781 189.632C18.988 206.292 28.5253 271.195 28.5253 271.195C28.5253 271.195 34.223 286.988 41.9024 288.35C49.5199 289.713 54.9698 290.518 54.9698 290.518Z"
        fill="url(#paint4_linear)"
      />
      <path
        opacity="0.56"
        d="M116.095 290.518C116.095 290.518 137.09 261.039 141.487 189.632C152.139 206.292 142.602 271.195 142.602 271.195C142.602 271.195 136.904 286.988 129.225 288.35C121.545 289.713 116.095 290.518 116.095 290.518Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M85.5013 258.623C54.0404 258.437 50.3246 252.059 50.3246 252.059L47.3519 248.219C45.1843 245.432 44.1934 241.964 44.3792 238.434L46.7326 203.752V161.516C46.7326 149.501 45.2462 137.61 42.3355 125.967L34.5322 95.0018C34.1606 93.5774 34.4083 92.0911 35.2754 90.8525L40.2918 83.3588C40.8492 82.5537 41.5923 81.9344 42.4594 81.5009C47.29 79.2714 64.4448 72.2113 85.5632 72.0874C106.62 72.2732 123.837 79.3333 128.667 81.5009C129.534 81.8725 130.277 82.5537 130.835 83.3588L135.851 90.8525C136.656 92.0911 136.904 93.5774 136.594 95.0018L128.791 125.967C125.88 137.61 124.394 149.501 124.394 161.516V203.752L126.747 238.434C126.995 241.964 125.942 245.432 123.775 248.219L120.802 252.059C120.678 252.12 117.024 258.437 85.5013 258.623Z"
        fill="#313234"
      />
      <path
        d="M85.5016 252.43C69.957 252.368 61.9679 250.696 58.1282 249.395C56.3322 248.776 54.7839 247.661 53.6072 246.175L52.3067 244.441C51.068 242.831 50.5107 240.849 50.6345 238.867L52.9879 204.31C52.9879 204.124 52.9879 203.876 52.9879 203.69V161.577C52.9879 149.067 51.4396 136.619 48.405 124.543L41.7165 98.0981C41.0352 95.4971 41.5307 92.7102 43.017 90.4807L43.6983 89.4278C44.7511 87.8176 46.2994 86.579 48.0334 85.8978C54.7839 83.1728 68.8422 78.4661 85.5016 78.2803C102.161 78.4041 116.219 83.1728 122.97 85.8978C124.766 86.6409 126.252 87.8796 127.305 89.4278L127.986 90.4807C129.473 92.7102 129.968 95.4971 129.287 98.0981L122.66 124.543C119.626 136.619 118.077 149.129 118.077 161.577V203.69C118.077 203.876 118.077 204.124 118.077 204.31L120.431 238.867C120.554 240.849 119.997 242.831 118.758 244.441L117.458 246.175C116.281 247.661 114.733 248.838 112.937 249.395C109.097 250.696 101.046 252.368 85.5016 252.43Z"
        fill="url(#paint6_linear)"
        stroke="#313234"
        stroke-width="0.575848"
        stroke-miterlimit="10"
      />
      <path
        d="M21.961 52.703C21.961 52.703 24.1286 40.6884 35.2142 31.2749C29.8882 44.3423 27.349 87.1365 27.1632 94.6921C26.6058 120.331 31.9319 282.343 31.9319 282.343L23.0758 262.463C21.6514 259.242 20.9082 255.774 20.9701 252.306L21.8991 196.63C21.961 194.215 22.3326 191.738 23.1377 189.446L23.6332 188.022L21.961 52.703Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M149.042 52.7034C149.042 52.7034 143.345 69.0531 143.84 94.6925C144.335 120.332 139.071 282.343 139.071 282.343L150.095 257.571L149.042 52.7034Z"
        fill="url(#paint8_linear)"
      />
      <path d="M51.9967 8.36084C51.9967 8.36084 57.4467 8.11312 55.0933 9.78525C48.0332 14.8017 32.7982 34.2479 26.5431 45.2716C26.0477 41.3081 27.0386 29.5412 28.8965 25.1441C32.055 17.4647 51.9967 8.36084 51.9967 8.36084Z" />
      <path d="M119.069 8.97998C119.069 8.97998 113.619 8.73226 115.972 10.4044C123.032 15.4208 138.267 34.8671 144.522 45.8908C145.018 41.9272 144.027 30.1603 142.169 25.7632C139.01 18.0838 119.069 8.97998 119.069 8.97998Z" />
      <path
        d="M38.1867 214.033C39.1157 213.785 39.797 213.042 39.9208 212.113C40.7878 205.549 44.0702 179.104 44.0702 153.093C44.0702 132.346 30.6931 97.9746 30.6931 97.9746C30.6931 97.9746 32.0556 144.609 32.0556 166.16C32.0556 183.625 34.409 204.743 35.276 212.051C35.4618 213.476 36.8243 214.405 38.1867 214.033Z"
        fill="#313234"
      />
      <path
        d="M132.817 214.033C131.888 213.785 131.207 213.042 131.083 212.113C130.216 205.549 126.934 179.104 126.934 153.093C126.934 132.346 140.311 97.9746 140.311 97.9746C140.311 97.9746 138.948 144.609 138.948 166.16C138.948 183.625 136.595 204.743 135.728 212.051C135.604 213.476 134.241 214.405 132.817 214.033Z"
        fill="#313234"
      />
      <path
        opacity="0.47"
        d="M37.6285 205.363C36.5757 195.454 35.1513 179.785 35.1513 166.222C35.1513 154.084 34.7178 133.771 34.3462 118.474C37.6285 129.497 40.9728 143.06 40.9728 153.093C40.9728 173.592 38.9291 194.215 37.6285 205.363Z"
        fill="url(#paint9_linear)"
        stroke="#313234"
        stroke-width="0.575848"
        stroke-miterlimit="10"
      />
      <path
        opacity="0.47"
        d="M133.436 205.363C134.489 195.454 135.913 179.785 135.913 166.222C135.913 154.084 136.347 133.771 136.718 118.474C133.436 129.497 130.092 143.06 130.092 153.093C130.092 173.592 132.135 194.215 133.436 205.363Z"
        fill="url(#paint10_linear)"
        stroke="#313234"
        stroke-width="0.575848"
        stroke-miterlimit="10"
      />
      <path
        d="M4.37217 123.057C4.18637 123.986 4.92954 124.791 5.85851 124.729C9.14084 124.543 16.0771 121.942 18.9879 120.827C19.793 120.518 20.2884 119.713 20.2265 118.907L20.1645 117.607L4.49603 122.19C4.49603 122.561 4.4341 122.809 4.37217 123.057Z"
        fill="#313234"
      />
      <path
        d="M22.8332 112.157L23.9479 107.822C23.9479 107.822 7.22658 113.024 4.62549 122.252L20.294 117.669L20.1082 113.829L22.8332 112.157Z"
        fill="#FAFAF8"
      />
      <path
        d="M166.631 123.057C166.817 123.986 166.074 124.791 165.145 124.729C161.862 124.543 154.926 121.942 152.015 120.827C151.21 120.518 150.715 119.713 150.777 118.907L150.839 117.607L166.507 122.19C166.507 122.561 166.631 122.809 166.631 123.057Z"
        fill="#313234"
      />
      <path
        d="M148.238 112.157L147.123 107.822C147.123 107.822 163.844 113.024 166.445 122.252L150.777 117.669L150.963 113.829L148.238 112.157Z"
        fill="#FAFAF8"
      />
    </g>
    <g clip-path="url(#clip0)">
      <g id="SECOND_ROW_DRIVER_SIDE" fill="#696C70">
        <path d="M57.356 175.06L57.4754 187.603C57.4754 188.319 58.0727 189.036 58.9089 189.036H73.2435C73.9603 189.036 74.677 188.439 74.677 187.603V173.746C74.677 173.029 74.0797 172.312 73.2435 172.312H60.1034C58.4311 172.432 57.356 173.507 57.356 175.06Z" />
        <path d="M59.9841 165.623C54.7281 165.623 50.5471 169.804 50.5471 175.06V198.951C50.5471 200.623 51.1444 200.743 51.7417 199.19L56.1615 189.633V175.06C56.1615 172.91 57.8339 171.237 59.9841 171.237H74.0799C74.4382 171.237 74.7966 170.998 74.916 170.64L75.6328 166.578C75.7522 166.101 75.2744 165.623 74.7966 165.623H59.9841Z" />
        <path d="M51.5025 202.534C51.1442 203.371 51.3831 204.326 52.0998 204.924C52.6971 205.282 53.4138 205.521 54.1306 205.521H55.6835C56.2808 205.521 56.9975 205.282 57.3559 204.685C57.9531 203.61 59.1477 202.893 60.4617 202.893H65.7177C67.0318 202.893 68.2263 203.61 68.943 204.685C69.3014 205.282 69.8987 205.521 70.6154 205.521H74.438V205.401V192.022C74.438 190.947 73.6018 190.111 72.5267 190.111H58.431C57.7142 190.111 56.9975 190.589 56.7586 191.186L51.5025 202.534Z" />
        <path d="M68.4653 206.715L68.3459 206.118V205.879C68.3459 205.76 68.3459 205.64 68.2264 205.521C67.868 204.685 66.9124 203.968 65.9568 203.968H60.5813C59.6256 203.968 58.67 204.565 58.3116 205.401C58.3116 205.521 58.1922 205.64 58.1922 205.76V205.999L58.0727 206.596L57.9532 207.552C57.8338 208.149 57.9532 208.866 58.4311 209.224C58.7894 209.702 59.3867 209.941 60.1034 209.941H66.6735C67.3902 209.941 67.868 209.702 68.3459 209.224C68.7042 208.746 68.9431 208.149 68.8237 207.552L68.4653 206.715Z" />
      </g>
      <g id="SECOND_ROW_MIDDLE" fill="#696C70">
        <path d="M75.6328 173.148V188.08C75.6328 188.558 75.9912 188.917 76.469 188.917H93.3122C93.79 188.917 94.1484 188.558 94.1484 188.08V173.148C94.1484 172.671 93.79 172.312 93.3122 172.312H76.469C76.1106 172.432 75.6328 172.79 75.6328 173.148Z" />
        <path d="M92.2371 165.623H77.6635C77.3052 165.623 76.9468 165.862 76.8273 166.22L75.9911 170.162C75.8717 170.64 76.2301 171.118 76.8273 171.118H92.9538C93.4317 171.118 93.9095 170.64 93.79 170.162L92.9538 166.22C92.9538 165.862 92.5955 165.623 92.2371 165.623Z" />
        <path d="M76.469 205.521H78.6192C78.9776 205.521 79.2165 205.401 79.3359 205.043C79.8138 203.729 81.2472 202.773 82.6807 202.773H87.9367C89.4896 202.773 90.8037 203.729 91.2815 205.043C91.4009 205.282 91.7593 205.521 91.9982 205.521H93.1928C93.6706 205.521 94.029 205.163 94.029 204.685V190.828C94.029 190.35 93.6706 189.992 93.1928 189.992H76.469C75.9912 189.992 75.6328 190.35 75.6328 190.828V204.565C75.6328 205.163 75.9912 205.521 76.469 205.521Z" />
        <path d="M90.5646 206.835L90.4452 206.238V205.999C90.4452 205.879 90.4452 205.76 90.3257 205.64C89.9673 204.685 89.0117 204.087 88.0561 204.087H82.8C81.7249 204.087 80.8887 204.685 80.5304 205.64C80.5304 205.76 80.4109 205.879 80.4109 205.999V206.238L80.2914 206.835L80.172 207.791C80.0525 208.388 80.172 209.105 80.6498 209.463C81.0082 209.941 81.6055 210.18 82.3222 210.18H88.8922C89.609 210.18 90.0868 209.941 90.5646 209.463C90.923 208.985 91.1619 208.388 91.0424 207.791L90.5646 206.835Z" />
      </g>
      <g id="SECOND_ROW_PASSENGER_SIDE" fill="#696C70">
        <path d="M109.558 165.623H94.8652C94.3873 165.623 94.1484 165.981 94.1484 166.459L94.9846 170.64C95.1041 170.998 95.343 171.237 95.7014 171.237H109.917C112.067 171.237 113.739 172.91 113.739 175.06V189.514C113.739 189.633 113.739 189.753 113.859 189.753L118.398 199.548C119.115 201.101 119.712 200.982 119.712 199.309V175.657C119.592 170.162 115.053 165.623 109.558 165.623Z" />
        <path d="M109.917 172.432H95.343V189.036H112.425L112.545 175.06C112.545 173.507 111.35 172.432 109.917 172.432Z" />
        <path d="M96.2988 190.111C95.821 190.111 95.4626 190.47 95.4626 190.947C95.4626 190.947 95.4626 201.579 95.4626 204.685C95.4626 205.163 95.821 205.521 96.2988 205.521H100.241C100.599 205.521 100.958 205.282 101.077 205.043C101.555 203.729 102.988 202.773 104.422 202.773H109.678C111.231 202.773 112.545 203.729 113.142 205.043C113.262 205.401 113.62 205.521 113.978 205.521H116.128C117.084 205.521 118.159 205.163 118.995 204.207C119.115 203.968 119.115 203.729 119.115 203.49C118.279 201.579 114.098 192.739 113.142 190.47C113.023 190.111 112.664 189.992 112.425 189.992H96.2988V190.111Z" />
        <path d="M112.067 206.835L111.947 206.238V205.879C111.947 205.76 111.947 205.76 111.828 205.64C111.47 204.685 110.514 203.968 109.558 203.968H104.302C103.227 203.968 102.271 204.685 102.033 205.521C102.033 205.64 101.913 205.76 101.913 205.76V206.118L101.794 206.715L101.674 207.552C101.555 208.149 101.674 208.866 102.152 209.224C102.51 209.702 103.108 209.941 103.824 209.941H110.394C111.111 209.941 111.589 209.702 112.067 209.224C112.425 208.746 112.664 208.149 112.545 207.552L112.067 206.835Z" />
      </g>
    </g>
    <g id="DRIVER" clip-path="url(#clip1)" fill="#696C70">
      <path d="M53.3938 127.326H55.1815V127.198C55.1815 127.07 55.1815 126.943 55.3092 126.815C55.3092 126.687 55.3092 126.687 55.3092 126.56L54.543 114.173C54.543 113.535 54.6707 112.896 54.9261 112.386C53.1384 111.236 51.8614 109.704 50.9676 107.661C50.3291 109.704 50.4568 112.258 50.5845 114.812C50.7122 115.961 50.7122 117.11 50.7122 118.26C50.7122 124.772 52.883 126.943 53.3938 127.326Z" />
      <path d="M55.8201 111.492C56.3309 111.109 56.8417 110.981 57.4801 110.981H70.6327C71.3988 110.981 72.0373 111.236 72.5481 111.747C74.5912 110.47 75.9958 108.682 76.8897 106.256C76.762 106.001 76.5066 105.745 76.2512 105.49C74.3358 103.191 70.3773 102.17 64.3756 102.17C58.2463 102.17 54.2878 103.191 52.3724 105.362C52.117 105.618 51.8616 105.873 51.7339 106.128C52.5001 108.427 53.9047 110.215 55.8201 111.492Z" />
      <path d="M77.6558 107.661C76.762 109.832 75.3573 111.492 73.4419 112.641C73.6973 113.152 73.825 113.663 73.825 114.173L73.0588 126.56C73.0588 126.687 73.0588 126.687 73.0588 126.815C73.0588 126.943 73.1865 127.07 73.1865 127.198V127.326H75.3573C75.8681 126.943 78.0389 124.772 78.0389 118.26C78.0389 117.11 78.1666 115.961 78.1666 114.812C78.2943 112.258 78.422 109.704 77.6558 107.661Z" />
      <path d="M55.9475 143.543C55.1813 143.543 54.4152 143.16 53.9044 142.649C53.2659 141.755 53.0105 140.606 53.1382 139.329L54.6705 130.135C53.649 130.39 52.8828 131.284 52.4997 132.306L50.8397 137.924C50.712 138.18 50.712 138.307 50.712 138.563C50.4566 141.5 50.712 145.075 52.2443 146.863C52.8828 147.501 53.649 147.885 54.5428 147.885H56.969C56.969 147.757 56.969 147.757 56.969 147.629L57.3521 145.586C57.4798 144.82 57.8629 144.181 58.5014 143.543H55.9475Z" />
      <path d="M77.6555 137.924L75.9955 132.306C75.6124 131.157 74.7186 130.263 73.697 130.007L75.2293 139.329C75.4847 140.606 75.1016 141.755 74.4632 142.649C73.9524 143.288 73.1862 143.543 72.4201 143.543H70.377C70.8877 144.054 71.3985 144.82 71.5262 145.586L71.9093 147.629C71.9093 147.757 71.9093 147.757 71.9093 147.885H74.2078C75.2293 147.885 75.8678 147.501 76.5063 146.863C78.0386 145.203 78.294 141.5 78.0386 138.563C77.6555 138.435 77.6555 138.18 77.6555 137.924Z" />
      <path d="M54.9261 141.755C55.0538 142.01 55.4369 142.266 55.9476 142.266H72.2925C72.8033 142.266 73.0587 142.01 73.3141 141.755C73.6972 141.244 73.9526 140.35 73.8249 139.584L72.2925 130.007L72.1648 129.241L72.0371 128.603C72.0371 128.603 72.0371 128.603 71.9094 128.603C71.3987 128.986 70.7602 129.241 70.1217 129.241C70.1217 129.241 70.1217 129.241 69.994 129.241H58.2461C58.2461 129.241 58.2461 129.241 58.1184 129.241C57.48 129.241 56.8415 128.986 56.3307 128.603C56.3307 128.603 56.3307 128.603 56.203 128.603L56.0753 129.241L55.9476 129.879L54.4153 139.457C54.2876 140.478 54.543 141.244 54.9261 141.755Z" />
      <path d="M70.3771 147.757L69.994 145.714C69.7386 144.437 68.5894 143.543 67.1847 143.543H61.3108C60.0338 143.543 58.7569 144.565 58.5015 145.714L58.1184 147.757C58.1184 148.012 58.1184 148.14 58.1184 148.395C58.1184 148.651 58.2461 148.778 58.2461 149.034C58.3738 149.289 58.5015 149.545 58.6292 149.672C59.14 150.183 59.7784 150.566 60.5446 150.566H67.9509C68.7171 150.566 69.3555 150.311 69.8663 149.672C69.994 149.545 70.1217 149.289 70.2494 149.034C70.3771 148.778 70.3771 148.651 70.3771 148.395C70.5048 148.268 70.5048 148.012 70.3771 147.757C70.3771 147.885 70.3771 147.757 70.3771 147.757Z" />
      <path d="M56.4583 126.049V126.56C56.4583 126.687 56.586 126.815 56.586 126.943C56.586 127.07 56.7137 127.198 56.8414 127.326C57.0968 127.709 57.4799 127.837 57.863 127.964C57.9906 127.964 58.1183 128.092 58.246 128.092H69.9939C70.1216 128.092 70.2493 128.092 70.377 127.964C70.7601 127.837 71.1432 127.709 71.3986 127.326C71.5263 127.198 71.5263 127.07 71.654 126.943C71.654 126.815 71.7817 126.687 71.7817 126.56V126.049V125.283L72.4201 114.173C72.4201 113.662 72.2924 113.152 71.9093 112.896C71.5263 112.513 71.1432 112.385 70.6324 112.385H57.6076C57.0968 112.385 56.586 112.641 56.3306 112.896C55.9475 113.152 55.6921 113.662 55.8198 114.173L56.4583 125.283V126.049Z" />
    </g>
    <g id="PASSENGER" clip-path="url(#clip2)" fill="#696C70">
      <path d="M117.391 127.326H115.603V127.198C115.603 127.07 115.603 126.943 115.475 126.815C115.475 126.687 115.475 126.687 115.475 126.56L116.242 114.173C116.242 113.535 116.114 112.896 115.859 112.386C117.646 111.236 118.923 109.704 119.817 107.661C120.456 109.704 120.328 112.258 120.2 114.812C120.072 115.961 120.072 117.11 120.072 118.26C120.072 124.772 117.902 126.943 117.391 127.326Z" />
      <path d="M114.965 111.492C114.454 111.109 113.943 110.981 113.305 110.981H100.152C99.3858 110.981 98.7474 111.236 98.2366 111.747C96.1935 110.47 94.7888 108.682 93.895 106.256C94.0227 106.001 94.278 105.745 94.5334 105.49C96.4488 103.191 100.407 102.17 106.409 102.17C112.538 102.17 116.497 103.191 118.412 105.362C118.668 105.618 118.923 105.873 119.051 106.128C118.285 108.427 116.88 110.215 114.965 111.492Z" />
      <path d="M93.1288 107.661C94.0227 109.832 95.4273 111.492 97.3427 112.641C97.0874 113.152 96.9597 113.663 96.9597 114.173L97.7258 126.56C97.7258 126.687 97.7258 126.687 97.7258 126.815C97.7258 126.943 97.5981 127.07 97.5981 127.198V127.326H95.4273C94.9166 126.943 92.7457 124.772 92.7457 118.26C92.7457 117.11 92.6181 115.961 92.6181 114.812C92.4904 112.258 92.3627 109.704 93.1288 107.661Z" />
      <path d="M114.837 143.543C115.603 143.543 116.37 143.16 116.88 142.649C117.519 141.755 117.774 140.606 117.646 139.329L116.114 130.135C117.136 130.39 117.902 131.284 118.285 132.306L119.945 137.924C120.073 138.18 120.073 138.307 120.073 138.563C120.328 141.5 120.073 145.075 118.54 146.863C117.902 147.501 117.136 147.885 116.242 147.885H113.816C113.816 147.757 113.816 147.757 113.816 147.629L113.433 145.586C113.305 144.82 112.922 144.181 112.283 143.543H114.837Z" />
      <path d="M93.1291 137.924L94.7892 132.306C95.1722 131.157 96.0661 130.263 97.0877 130.007L95.5553 139.329C95.2999 140.606 95.683 141.755 96.3215 142.649C96.8323 143.288 97.5984 143.543 98.3646 143.543H100.408C99.8969 144.054 99.3862 144.82 99.2585 145.586L98.8754 147.629C98.8754 147.757 98.8754 147.757 98.8754 147.885H96.5769C95.5553 147.885 94.9169 147.501 94.2784 146.863C92.746 145.203 92.4907 141.5 92.746 138.563C93.1291 138.435 93.1291 138.18 93.1291 137.924Z" />
      <path d="M115.859 141.755C115.731 142.01 115.348 142.266 114.837 142.266H98.4921C97.9814 142.266 97.726 142.01 97.4706 141.755C97.0875 141.244 96.8321 140.35 96.9598 139.584L98.4921 130.007L98.6198 129.241L98.7475 128.603C98.7475 128.603 98.7475 128.603 98.8752 128.603C99.386 128.986 100.024 129.241 100.663 129.241C100.663 129.241 100.663 129.241 100.791 129.241H112.539C112.539 129.241 112.539 129.241 112.666 129.241C113.305 129.241 113.943 128.986 114.454 128.603C114.454 128.603 114.454 128.603 114.582 128.603L114.709 129.241L114.837 129.879L116.369 139.457C116.497 140.478 116.242 141.244 115.859 141.755Z" />
      <path d="M100.408 147.757L100.791 145.714C101.046 144.437 102.195 143.543 103.6 143.543H109.474C110.751 143.543 112.028 144.565 112.283 145.714L112.666 147.757C112.666 148.012 112.666 148.14 112.666 148.395C112.666 148.651 112.539 148.778 112.539 149.034C112.411 149.289 112.283 149.545 112.155 149.672C111.645 150.183 111.006 150.566 110.24 150.566H102.834C102.068 150.566 101.429 150.311 100.918 149.672C100.791 149.545 100.663 149.289 100.535 149.034C100.408 148.778 100.408 148.651 100.408 148.395C100.28 148.268 100.28 148.012 100.408 147.757C100.408 147.885 100.408 147.757 100.408 147.757Z" />
      <path d="M114.326 126.049V126.56C114.326 126.687 114.199 126.815 114.199 126.943C114.199 127.07 114.071 127.198 113.943 127.326C113.688 127.709 113.305 127.837 112.922 127.964C112.794 127.964 112.666 128.092 112.539 128.092H100.791C100.663 128.092 100.535 128.092 100.408 127.964C100.025 127.837 99.6415 127.709 99.3861 127.326C99.2584 127.198 99.2584 127.07 99.1307 126.943C99.1307 126.815 99.003 126.687 99.003 126.56V126.049V125.283L98.3645 114.173C98.3645 113.662 98.4922 113.152 98.8753 112.896C99.2584 112.513 99.6415 112.385 100.152 112.385H113.177C113.688 112.385 114.199 112.641 114.454 112.896C114.837 113.152 115.093 113.662 114.965 114.173L114.326 125.283V126.049Z" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="20.8899"
        y1="151.021"
        x2="150.16"
        y2="151.021"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1452" stop-color="white" />
        <stop offset="0.6215" stop-color="#C5C3C4" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="52.2489"
        y1="288.208"
        x2="118.801"
        y2="288.208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9C9A9B" />
        <stop offset="1" stop-color="#ADABAC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="32.2918"
        y1="49.997"
        x2="53.8401"
        y2="52.4326"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1452" stop-color="white" />
        <stop offset="1" stop-color="#908E8F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="138.685"
        y1="49.1448"
        x2="116.531"
        y2="51.6488"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1452" stop-color="white" />
        <stop offset="1" stop-color="#908E8F" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="55.1368"
        y1="235.6"
        x2="14.377"
        y2="249.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9C9A9B" />
        <stop offset="0.8548" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="115.913"
        y1="235.6"
        x2="156.673"
        y2="249.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9C9A9B" />
        <stop offset="0.8548" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="85.525"
        y1="252.44"
        x2="85.525"
        y2="78.2911"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.6273" stop-color="#ADABAC" />
        <stop offset="1" stop-color="#403F40" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="36.0172"
        y1="156.832"
        x2="15.7957"
        y2="156.832"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1494" stop-color="#A9A7A8" />
        <stop offset="0.8548" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="138.486"
        y1="167.548"
        x2="154.18"
        y2="167.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1494" stop-color="#A9A7A8" />
        <stop offset="0.8548" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="37.6621"
        y1="205.339"
        x2="37.6621"
        y2="118.449"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.6273" stop-color="#ADABAC" />
        <stop offset="1" stop-color="#403F40" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="133.387"
        y1="205.339"
        x2="133.387"
        y2="118.449"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.6273" stop-color="#ADABAC" />
        <stop offset="1" stop-color="#403F40" />
      </linearGradient>
      <clipPath id="clip0">
        <rect
          width="69.0453"
          height="44.5569"
          fill="white"
          transform="translate(50.5471 165.623)"
        />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="27.8374"
          height="48.3962"
          fill="white"
          transform="translate(50.4568 102.17)"
        />
      </clipPath>
      <clipPath id="clip2">
        <rect
          width="27.8374"
          height="48.3962"
          fill="white"
          transform="matrix(-1 0 0 1 120.328 102.17)"
        />
      </clipPath>
    </defs>
  </svg>
);
