import { LDClient, LDFlagChangeset } from 'launchdarkly-js-client-sdk';
import { useEffect, useState } from 'react';

import { useLDClient } from '../services/launch-darkly';
import { useAuth } from './useAuth';

/**
 * Get the value of a feature flag.
 */
export const useFeatureFlag = <T,>(
  featureFlag: string,
  defaultValue = false,
  options?: {
    /**
     * If set to `true` we return `undefined` until mount. This could be
     * useful if you know the auth user will be available on mount and
     * might depend on it, to prevent incorrect flag value before auth.
     */
    shouldWaitForMount?: boolean;
  },
): T => {
  const { user } = useAuth();
  const ldClient = useLDClient();
  const [isEnabled, setEnabled] = useState(
    !options?.shouldWaitForMount
      ? ldClient?.variation(featureFlag, defaultValue)
      : undefined,
  );

  useEffect(() => {
    const subscribeToChangesForFlag = (client: LDClient, flagKey: string) => {
      client.on('change', (changes: LDFlagChangeset) => {
        if (changes[flagKey]) {
          setEnabled(changes[flagKey].current);
        }
      });
    };

    if (user?.sub && ldClient) {
      ldClient
        ?.identify({
          key: user.sub,
          kind: 'user',
          name: user.name,
          email: user.email,
          custom: {
            id: user.sub,
          },
        })
        .then(() => {
          const variation = ldClient?.variation(featureFlag, defaultValue);
          if (
            window.location.search.includes('debug=ld') &&
            (window.location.hostname.includes('localhost') ||
              window.location.hostname.includes('staging'))
          ) {
            console.log(
              `LD Client ${
                ldClient ? 'found' : 'not found'
              }; loaded feature flag ${featureFlag} with value ${variation} (default: ${defaultValue}) for user ${
                user.sub
              }`,
            );
          }
          setEnabled(variation);
          subscribeToChangesForFlag(ldClient, featureFlag);
        });
    } else if (ldClient) {
      setEnabled(ldClient?.variation(featureFlag, defaultValue));
      subscribeToChangesForFlag(ldClient, featureFlag);
    }
  }, [user?.sub]);

  return isEnabled;
};
