import { useEffect, useState } from 'react';

export const useHashString = (
  inputString: string | undefined,
  truncateLength: number = 10,
) => {
  if (!inputString) return;

  const [hashedId, setHashedId] = useState<string>();

  useEffect(() => {
    const hashString = async () => {
      const encoder = new TextEncoder();
      const data = encoder.encode(inputString);

      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('');

      setHashedId(hashHex.substring(0, truncateLength));
    };

    hashString();
  }, [inputString, truncateLength]);

  return hashedId;
};
