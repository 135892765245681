import classNames from 'classnames';
import { isEmpty, isPlainObject } from 'lodash';
import React from 'react';
import ReactAnimatedWeather from 'react-animated-weather';

export const WeatherForecast: React.FC<{
  className?: string;
  small?: boolean;
  forecast: any;
}> = ({ forecast, small = false, className }) => {
  if (!isPlainObject(forecast) || isEmpty(forecast) || !forecast?.icon) {
    return null;
  }
  const iconKey = forecast.icon.toUpperCase().replace(/-/g, '_');
  const forecastLabel = `Animated illustration to describe a forecast of "${forecast?.icon?.toLowerCase?.()}"`;
  const size = small ? 36 : 120;

  return (
    <div
      data-not-copyable
      className={classNames(
        'text-center',
        { 'mt-4 mb-4 w-full': !small },
        className,
      )}
    >
      {/*
          The "react-animated-weather" package does not provide a way to
          markup its contents for screen readers (uses <canvas>
          internally).

          The WAI-ARIA format allows us to "typecast" this to an "img"
          (via the "role" prop) and then use an "aria-label" to describe
          the imagery to screen reader users.
        */}
      <div
        role="img"
        aria-label={forecastLabel}
        className="mx-auto"
        style={{ maxWidth: size }}
      >
        <ReactAnimatedWeather
          color="#718096"
          icon={iconKey}
          size={size}
          animate={iconKey.indexOf('NIGHT') === -1} // Moon animation issues on mobile.
        />
      </div>
      <div className="text-l">{forecast.summary}</div>
      <div className="text-m">{forecast.temperature.toFixed(0)}°F</div>
    </div>
  );
};
export default WeatherForecast;
