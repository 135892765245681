import { H } from 'highlight.run';

export interface AnalyticsDispatcher {
  injector?: (payload: any) => void;
  method: (...args: any[]) => void;
}

export type AnalyticsMode = 'log' | 'send';

export interface AnalyticsOptions {
  /** Methods with injectors (transformers) to be called from our providers based on identify calls */
  identifiers?: AnalyticsDispatcher[];

  /** A mode of handling analytics to either log (typically in dev mode) or to send */
  mode?: AnalyticsMode;

  /** Methods to be called from our providers based on page event calls */
  pageDispatchers?: AnalyticsDispatcher[];

  /** Methods to be called from our providers based on tracking event calls */
  trackers?: AnalyticsDispatcher[];
}

export class Analytics {
  /** A mode of handling analytics to either log (typically in dev mode) or to send */
  private mode: AnalyticsMode = 'send';

  constructor(options?: AnalyticsOptions) {
    if (options?.identifiers) {
      this.identifiers = options.identifiers;
    }
    if (options?.mode) {
      this.mode = options.mode;
    }
    if (options?.pageDispatchers) {
      this.pageDispatchers = options.pageDispatchers;
    }
    if (options?.trackers) {
      this.trackers = options.trackers;
    }
  }

  /** Methods with injectors (transformers) to be called from our providers based on identify calls */
  public identifiers: AnalyticsDispatcher[] = [
    {
      method: (...args) => window.analytics?.identify(...args),
      injector: (payload: any) => {
        if (!payload) {
          payload = {};
        }
        const isMobileUserAgent = /Mobi/i.test(window.navigator.userAgent);
        payload.isMobileUserAgent = isMobileUserAgent;
        payload.lastReferrer = document.referrer;
        return payload;
      },
    },
    {
      method: H.identify,
      injector: (payload: any) => {
        if (payload?.name) {
          return { ...payload, highlightDisplayName: payload.name };
        }
        return payload;
      },
    },
  ];

  /** Methods to be called from our providers based on page event calls */
  public pageDispatchers: AnalyticsDispatcher[] = [
    {
      method: () => window.analytics?.page(),
    },
  ];

  /** Methods to be called from our providers based on tracking event calls */
  public trackers: AnalyticsDispatcher[] = [
    { method: (event, ...args) => window.analytics?.track(event, ...args) },
    {
      method: H.track,
      injector: (payload: any) => {
        return payload;
      },
    },
  ];

  /** A method to send identity data to a list of providers to connect event tracking with */
  public identify(userId: string, userProperties?: any) {
    switch (this.mode) {
      case 'log':
        console.log(`analytics (log mode): identify`, {
          userId,
          userProperties,
        });
        break;
      default:
        this.identifiers.forEach(identifier => {
          const { method, injector } = identifier;
          const payload = injector ? injector(userProperties) : userProperties;
          method(userId, payload);
        });
        break;
    }
  }

  /** A method to send page tracking */
  public page() {
    switch (this.mode) {
      case 'log':
        console.log(`analytics (log mode): page`);
        break;
      default:
        this.pageDispatchers.forEach(identifier => {
          const { method } = identifier;
          method();
        });
        break;
    }
  }

  /** A method to send tracking events to a list of providers */
  public track(eventName: string, eventProperties?: any) {
    switch (this.mode) {
      case 'log':
        console.log(`analytics (log mode): track`, {
          eventName,
          eventProperties,
        });
        break;
      default:
        this.trackers.forEach(tracker => {
          const { method, injector } = tracker;
          const payload = injector
            ? injector(eventProperties)
            : eventProperties;
          method(eventName, payload);
        });
        break;
    }
  }
}
