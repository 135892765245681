/**
 * Helpers for setting up the LaunchDarkly client-side SDK.
 */
import { basicLogger } from 'launchdarkly-js-client-sdk';

/**
 * For headless Chrome-based PDF rendering, we need to disable streaming because otherwise
 * the Puppeteer process will hang as the Websocket connection is never closed.
 */
function shouldDisableFeatureFlagStreaming() {
  const userAgent = window.navigator.userAgent;
  const isHeadlessChrome = userAgent.includes('HeadlessChrome');
  return isHeadlessChrome;
}

/**
 * Builds a provider config object that can be passed to `withLDProvider`.
 * Note that we pass a fixed context key since unless this is overridden later
 * with a specific User context, we want to use app-wide flags instead.
 */
export function buildLaunchDarklyClientSideProviderConfig({
  clientSideID,
  appSlug,
  appName,
}: {
  clientSideID?: string;
  appSlug: string;
  appName: string;
}) {
  return {
    clientSideID: clientSideID || '',
    options: {
      streaming: shouldDisableFeatureFlagStreaming() ? false : undefined,
      logger: basicLogger({ level: 'error' }),
    },
    context: {
      kind: 'user',
      key: `generic-${appSlug}-${window.location.hostname.replace(/\./g, '_')}`,
      name: `${appName} @ ${window.location.hostname}`,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  };
}
