import React from 'react';
import Div100vh from 'react-div-100vh';

import { LOADING_MEDIA_CLASS_NAME } from '@assured/shared-types/Claim/Media';

import loader from '../images/loader.svg';

interface PageLoaderProps {
  style?: React.CSSProperties;
  text?: string;
}

const PageLoader: React.FC<PageLoaderProps> = ({ style, text }) => {
  return (
    <Div100vh
      key="loaderWrapper"
      style={style || { minHeight: '100rvh' }}
      className="flex flex-col items-center justify-center text-center"
    >
      <img
        className={LOADING_MEDIA_CLASS_NAME}
        key="loader"
        src={loader}
        style={{ height: 57 }}
      />
      {text ? <div className="mt-6 text-gray-500 font-bold">{text}</div> : null}
    </Div100vh>
  );
};
export default PageLoader;
