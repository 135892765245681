declare global {
  interface Window {
    newrelic?: any;
  }
}
/**
 * Logs an error to New Relic for monitoring and debugging purposes.
 *
 * This function is a wrapper around New Relic's `noticeError` method, allowing for the
 * logging of errors with optional custom attributes for more detailed monitoring.
 *
 */
export function noticeError(
  error: unknown,
  customAttributes?: { [key: string]: string | number | boolean },
): void {
  if (!error) {
    return;
  }
  // newrelic is a global that is initialized in the header, but not guaranteed in all environments.
  if (typeof window.newrelic !== undefined) {
    try {
      window.newrelic.noticeError(error, customAttributes);
    } catch (_) {
      // swallow the error for now
    }
  }
}
