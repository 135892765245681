import ReactConfetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

import { ConfettiWidgetSpec } from '@assured/step-renderer';

import {
  WidgetComponentSharedProps,
  WidgetFC,
} from '../types/widgetComponentTypes';

export const Confetti: WidgetFC<
  WidgetComponentSharedProps<ConfettiWidgetSpec>
> = ({ widget, className }) => {
  const { width, height } = useWindowSize();
  return (
    <ReactConfetti
      width={width}
      height={height}
      style={{ position: 'fixed' }}
      recycle={false}
      numberOfPieces={300}
    />
  );
};

export default Confetti;
