import React, { useState } from 'react';

import CoverageIcon from '../../elements/CoverageIcon';
import Modal from '../../Modal';
import {
  InlineComponentSharedProps,
  InlineFC,
} from '../types/inlineComponentTypes';

import type { PolicyInlineComponentSpec } from '@assured/step-renderer';

type PolicyInfoSection = {
  icon?: string;
  inlineText?: string;
  insurerName?: string;
  modalTitle?: string;
  modalText?: string;
};

const Policy: InlineFC<
  InlineComponentSharedProps<PolicyInlineComponentSpec>
> = ({ value: policyData }) => {
  const [open, setOpen] = useState(false);
  const [policyInfoSection, setPolicyInfoSection] = useState<
    PolicyInfoSection | undefined
  >(undefined);
  const showLinkAndModal = !policyData.value.suppressLearnMoreLink;
  const policyInfo = policyData.value.policyInfo ?? [];
  const policySections: PolicyInfoSection[] = Array.isArray(policyInfo)
    ? policyInfo
    : [policyInfo];

  return (
    <>
      <div
        className={`rounded-md ${
          policyData?.secondary
            ? 'bg-gray-100 text-gray-600'
            : 'bg-blue-100 text-blue-600'
        } flex flex-col  my-4 divide-y divide-blue-200 px-4`}
      >
        {policySections.map(policySection => (
          <div className="flex flex-row py-4 px-4">
            <CoverageIcon
              icon={policySection.icon ?? 'roadside_assistance'}
              className="w-16 -ml-4 -my-2"
            />
            <div className="flex-1 text-xs font-medium leading-4 whitespace-pre-wrap">
              {policySection.inlineText ? (
                policySection.inlineText
              ) : (
                <>
                  Good news—your tow is covered by {policySection.insurerName}!
                </>
              )}{' '}
              {showLinkAndModal && (
                <span
                  role="button"
                  tabIndex={0}
                  className="inline-flex items-center border-blue-600 border-b-2 font-semibold cursor-pointer"
                  onKeyUp={({ key }) => {
                    if (key === 'Enter' || key === ' ') {
                      setPolicyInfoSection(policyInfoSection);
                      setOpen(true);
                    }
                  }}
                  onClick={() => {
                    setPolicyInfoSection(policyInfoSection);
                    setOpen(true);
                  }}
                >
                  Learn more
                  <svg
                    className="w-5 h-5 ml-1 inline-block"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </span>
              )}
            </div>
            <br />
          </div>
        ))}
      </div>
      <Modal
        title={policyInfoSection?.modalTitle ?? 'Tow Coverage'}
        icon="check"
        body={
          <div>
            {policyInfoSection?.modalText ??
              'Sorry, policy detailed info not found.'}
          </div>
        }
        open={open}
        customDismiss={() => {
          setOpen(false);
          setPolicyInfoSection(undefined);
        }}
        actions={[
          {
            title: 'Coverage details',
            primary: false,
            className: '',
            onClick: history => history.push('/policy/coverages?inline=true'),
          },
          {
            title: 'Got it',
            primary: true,
            className: '',
            onClick: () => setOpen(false),
          },
        ]}
      />
    </>
  );
};

export default Policy;
