import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { gql, useLazyQuery } from '@apollo/client';

export const LOOKUP_CASE_OR_CLAIM = gql`
  query lookupCaseOrClaimByIdentifier($identifier: String!) {
    lookupCaseOrClaimByIdentifier(identifier: $identifier) {
      caseId
      claimId
    }
  }
`;

const ClaimLookup: React.FC = () => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');

  const [lookupCaseOrClaim, { loading }] = useLazyQuery(LOOKUP_CASE_OR_CLAIM, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.lookupCaseOrClaimByIdentifier.caseId) {
        history.push(`/cases/${data.lookupCaseOrClaimByIdentifier.caseId}`);
      } else if (data.lookupCaseOrClaimByIdentifier.claimId) {
        history.push(`/claims/${data.lookupCaseOrClaimByIdentifier.claimId}`);
      } else {
        window.alert(
          'Claim not found. Double check the claim number entered is correct and try again.',
        );
      }
    },
    onError: e => {
      window.alert(
        `Failed to lookup case: ${JSON.stringify(
          e,
        )}. Please contact support@assured.claims.`,
      );
    },
  });

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        lookupCaseOrClaim({
          variables: { identifier: searchInput.trim().replace(/^#/, '') },
        });
      }}
    >
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            className="block h-full focus:outline-none border border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 w-40 rounded-none rounded-l-md pl-10 transition ease-in-out duration-150 sm:block sm:text-sm sm:leading-5"
            placeholder="Claim ID"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            autoComplete="off"
            data-enable-shortcuts="meta+k"
            aria-label="Claim ID"
          />
        </div>
        <button
          type="submit"
          className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
        >
          {loading ? 'Loading...' : 'Lookup'}
        </button>
      </div>
    </form>
  );
};

export default ClaimLookup;
