import classNames from 'classnames';
import { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export const TabLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  ({ className, activeClassName, children, ...rest }, ref) => {
    const linkClasses = classNames(
      'inline-flex items-center justify-center px-6 py-2 text-gray-700 border-b-2 border-transparent hover:bg-gray-100 hover:text-gray-700 rounded-t-lg font-medium mr-2 last:mr-0 active:bg-blue-50 active:text-indigo-600 active:border-b-indigo-600 focus:outline-none focus:bg-gray-100',
      className,
    );

    const activeClasses = classNames(
      'border-b-2 bg-indigo-50 text-indigo-600 border-b-indigo-600  hover:bg-indigo-50 hover:text-indigo-600',
      activeClassName,
    );

    return (
      <NavLink
        activeClassName={activeClasses}
        className={linkClasses}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </NavLink>
    );
  },
);
