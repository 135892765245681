import type { GraphicInlineComponentSpec } from '@assured/step-renderer';

import { useTenantConfig } from '../../../hooks';
import TenantLogo from '../../TenantLogo';
import {
  InlineComponentSharedProps,
  InlineFC,
} from '../types/inlineComponentTypes';

const Graphic: InlineFC<
  InlineComponentSharedProps<GraphicInlineComponentSpec>
> = ({ value: graphicData }) => {
  const tenantConfig = useTenantConfig();

  return (
    <div>
      {graphicData.value.graphic.type === 'tenantLogo' ? (
        <TenantLogo
          tenant={graphicData.value.graphic.tenant}
          displayName={tenantConfig?.displayName}
          size="large"
          className={'block mx-auto mb-8'}
        />
      ) : null}
    </div>
  );
};

export default Graphic;
