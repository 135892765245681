// All values from @prisma/client/VehicleType or
// @prisma/client/NonStandardVehicleType that
// could have their own seat or damage map frames.
export enum BodyType {
  'BOX_TRUCK',
  'SEDAN',
  'CAR', // previously entered, unclassified
  'MOTORCYCLE',
  'PICKUP_TRUCK',
  'SUV',
  'TESLA',
  'ATV',
  'BOAT',
  'DIRT_BIKE',
  'PLANE',
  'RV',
  'VAN',
}

export interface VehicleLayout {
  seats: number;
  doors: number;
  body: BodyType;
}
