import React from 'react';

export default () => (
  <svg x="0px" y="0px" width="400px" height="500px" viewBox="0 50 400 500">
    <g>
      <g>
        <polygon points="289.21,164 289.114,163.959 289.139,164 		" />
        <path
          d="M286,159.036v-31.885C286,104.246,266.77,78,242.738,78h-85.412C133.28,78,115,104.247,115,127.152v31.854l-30,9.964
			v11.204l30-8.849V302v14v163.314C115,502.862,133.783,522,157.326,522h85.412C266.285,522,286,502.862,286,479.315V316v-14
			V171.338l30,8.836v-11.205L286,159.036z M312,175.602l-25.292-7.46L282,166.818v3.511V302v14v163.316
			C282,500.978,264.401,519,242.738,519h-85.412C135.667,519,118,500.978,118,479.315V316v-14V167.639v-0.899
			c-1,0.26-2,0.647-3,1.017v0.006l-27,7.838v-4.169l27-8.871v0.016l3-1.133v-0.638v-33.654c0-20.716,16.217-44.696,37.421-46.049
			c-0.759,6.495-4.629,29.62-23.295,61.765c-0.474,0.816-0.191,1.861,0.625,2.334c0.27,0.157,0.568,0.231,0.859,0.231
			c0.589,0,1.163-0.323,1.48-0.869C154.678,110.832,158.267,87,158.901,81h81.602c0.634,6,4.221,29.832,23.81,63.564
			c0.317,0.546,0.89,0.86,1.479,0.86c0.291,0,0.586-0.07,0.856-0.226c0.816-0.474,1.093-1.516,0.619-2.332
			c-18.69-32.186-22.57-55.327-23.322-61.787C265.46,81.97,282,106.222,282,127.152v30.749v3.601v0.009l4,1.13v-0.005l3.114,1.323
			l-0.037-0.062L312,171.434V175.602z"
        />
      </g>
      <path
        d="M272.739,165.519c-20.453-10.345-44.973-15.812-70.909-15.812c-26.747,0-52.823,6.072-73.422,17.097
		l-1.131,0.605l0.439,1.204c4.94,13.568,13.9,37.85,18.415,48.03l0.498,1.124l1.196-0.285c17.345-4.133,36.946-6.318,56.682-6.318
		c17.005,0,33.469,1.56,48.936,4.636l1.14,0.227l0.489-1.054c4.15-8.951,13.303-33.682,18.39-47.627l0.45-1.233L272.739,165.519z
		 M270.23,167.572c-4.756,13.018-13.154,35.683-17.362,45.103c-15.32-2.965-31.589-4.468-48.361-4.468
		c-19.492,0-38.903,2.114-56.163,6.117c-4.514-10.445-12.769-32.791-17.408-45.512c19.988-10.415,45.148-16.148,70.894-16.148
		C226.791,152.664,250.433,157.818,270.23,167.572z"
      />
      <path
        d="M150.896,466.805l-18.382,27h138.63l-18.39-27H150.896z M138.888,490.805l13.839-21h98.196l13.845,21
		H138.888z"
      />
    </g>
    <g id="THIRD_ROW_DRIVER_SIDE">
      <path
        d="M173.596,435.27c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
		c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
		c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
		c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C173.787,437.173,173.596,435.27,173.596,435.27z"
      />
      <path
        d="M136.04,405.155c0,0,0.383-0.275,2.196,0.613c1.698,0.715,5.124,3.233,7.409,6.233h3.427
		c0.011,2,0.013,10.171-1.957,19.129c-1.117,0.387-2.084,1.183-2.546,2.121c-0.005,0.007-0.358,0.658-0.432,1.671
		c-0.009,0.065-0.248,1.698,1.045,4.725c0.242,0.621,0.536,0.354,0.87,1.354h-6.403c-3.732,0-6.648-2.325-7.648-5.849v-0.839
		c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094v-2.154C132,410.023,132.181,405.826,136.04,405.155z"
      />
      <path
        d="M168.023,412h-17.516c0.011,2,0.012,10.017-1.896,18.874l7.87,0.126h13.308c0.101,0,0.201-0.108,0.302-0.101
		C168,421.366,168.022,412,168.023,412z"
      />
      <path
        d="M180,412h-4.798h-2.342h-1.6h-0.854h-0.973c-0.011,2-0.013,10.171,1.957,19.129c1.117,0.387,2.084,1.183,2.546,2.121
		c0.005,0.007,0.358,0.658,0.432,1.671c0.009,0.065,0.248,1.698-1.045,4.725c-0.242,0.621-0.128,0.354-0.462,1.354h4.377h0.583H180
		V412z"
      />
      <path
        d="M180,368h-4.256h-0.472h-0.238h-1.602h-13.276h-1.39h-15.299h-1.247h-0.432c-4.112,0-5.451,4.43-5.451,4.43
		c-4.721,15.104-0.285,28.871,1.091,32.599l0.072-0.222c0.189,0.055,5.501,2.193,8.706,6.193h0.797h1.317h1.437h9.01h1.39h7.015
		h1.437h0.562h2.157H180V368z"
      />
    </g>
    <g id="THIRD_ROW_MIDDLE">
      <path
        d="M186.417,433.274c0.462-0.938,1.429-1.758,2.546-2.145c1.97-8.957,1.968-17.129,1.957-19.129h-1.726h-1.701h-1.857H182v29
		h5.486c-1.815-4-1.501-6.135-1.501-6.135C186.059,433.852,186.412,433.281,186.417,433.274z"
      />
      <g>
        <path
          d="M221,412h-4.346h-0.568h-1.289h-3.427c-0.011,2-0.013,10.171,1.957,19.129c1.117,0.387,2.084,1.183,2.546,2.121
			c0.005,0.007,0.358,0.591,0.432,1.604c0,0,0.314,2.147-1.501,6.147h3.428h2.561H221V412z"
        />
        <g>
          <path
            d="M215.445,435.27c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
				c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
				c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
				c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C215.636,437.173,215.445,435.27,215.445,435.27z"
          />
          <g>
            <path
              d="M209.872,412h-17.516c0.011,2,0.012,10.017-1.896,18.874l7.87,0.126h13.308c0.101,0,0.201-0.108,0.302-0.101
					C209.848,421.366,209.871,412,209.872,412z"
            />
          </g>
          <rect x="182" y="368" width="39" height="43" />
        </g>
      </g>
    </g>
    <g id="THIRD_ROW_PASSENGER_SIDE">
      <path
        d="M228.705,435.27c0.066-0.907,0.256-1.458,0.256-1.458c0.606-1.231,1.706-2.078,3.172-2.078l22.184,0.035
		c1.466,0,2.465,0.83,3.071,2.061c0,0,0.185,0.542,0.251,1.449c0,0,0.189,1.899-1.033,4.756c0,0-1.418,4.018-4.505,4.98
		c0,0-3.399,0.985-8.647,0.988c-0.092,0.001-0.179-0.001-0.269,0c-0.09,0-0.177,0.001-0.268-0.001
		c-5.274-0.003-8.659-0.989-8.659-0.989c-3.087-0.961-4.523-4.982-4.523-4.982C228.514,437.173,228.705,435.27,228.705,435.27z"
      />
      <path
        d="M266.111,405.155c0,0-0.308-0.275-2.121,0.613c-1.698,0.715-5.048,3.233-7.333,6.233h-3.427
		c-0.011,2-0.013,10.171,1.957,19.129c1.117,0.387,2.084,1.183,2.546,2.121c0.005,0.007,0.358,0.658,0.432,1.671
		c0.009,0.065,0.248,1.698-1.045,4.725c-0.242,0.621-0.536,0.354-0.87,1.354h6.403c3.732,0,6.347-2.325,7.347-5.849v-0.839
		c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094v-2.154C270,410.023,269.97,405.826,266.111,405.155z"
      />
      <path
        d="M234.278,412h17.516c-0.011,2-0.012,10.017,1.896,18.874L245.82,431h-13.308c-0.101,0-0.201-0.108-0.302-0.101
		C234.302,421.366,234.279,412,234.278,412z"
      />
      <path
        d="M223,412h4.1h2.342h1.6h0.854h0.973c0.011,2,0.013,10.171-1.957,19.129c-1.117,0.387-2.084,1.183-2.546,2.121
		c-0.005,0.007-0.358,0.658-0.432,1.671c-0.009,0.065-0.248,1.698,1.045,4.725c0.242,0.621,0.128,0.354,0.462,1.354h-4.377h-0.583
		H223V412z"
      />
      <path
        d="M223,368h3.557h0.472h0.238h1.602h13.276h1.39h15.299h1.247h0.432c4.112,0,5.451,4.43,5.451,4.43
		c4.721,15.104,0.285,28.871-1.091,32.599l-0.072-0.222c-0.189,0.055-5.501,2.193-8.706,6.193h-0.797h-1.317h-1.437h-9.01h-1.39
		h-7.015h-1.437h-0.562h-2.157H223V368z"
      />
    </g>
    <g id="DRIVER">
      <path
        d="M137.798,218.576c0.189,0.055,5.501,2.424,8.706,6.424h0.797h1.317h1.436h9.01h8.405h1.437h0.562h2.157
		c3.205-4,8.516-6.502,8.706-6.556l0.071-0.094c1.376-3.729,5.812-17.711,1.091-32.814c0,0-1.339-4.535-5.451-4.535h-0.472h-0.238
		h-1.602h-14.666h-15.299H142v0.418v-0.418h0.086c-4.112,0-5.451,4.738-5.451,4.738c-4.721,15.104-0.285,29.06,1.091,32.788
		L137.798,218.576z"
      />
      <g>
        <path
          d="M173.169,249.741c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
			c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
			c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
			c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C173.36,251.644,173.169,249.741,173.169,249.741z"
        />
        <path
          d="M172.41,256h6.018c3.732,0,6.572-2.854,7.572-6.378v-0.839c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094
			v-2.154c0,0-0.142-4.197-4.001-4.868c0,0-0.364-0.01-2.177,0.877c-1.698,0.715-5.104,2.497-7.389,6.497h-3.427
			c-0.011,1-0.013,9.907,1.957,18.864c1.117,0.387,2.084,1.051,2.546,1.988c0.005,0.007,0.358,0.592,0.432,1.605
			c0.007,0.055,0.183,1.469-0.571,3.716L172.41,256z"
        />
        <path
          d="M145.081,256h-5.859c-3.732,0-7.222-2.854-8.222-6.378v-0.839c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094
			v-2.154c0,0,0.468-4.197,4.327-4.868c0,0,0.527-0.01,2.339,0.877c1.698,0.715,5.267,2.497,7.552,6.497h3.427
			c0.011,1,0.013,9.907-1.957,18.864c-1.117,0.387-2.084,1.051-2.546,1.988c-0.005,0.007-0.358,0.789-0.432,1.802
			C143.709,249.655,143.506,253,145.081,256z"
        />
        <g>
          <path
            d="M167.596,227H150.08c0.011,1,0.012,9.517-1.896,18.374l7.87-0.374h13.308c0.101,0,0.201,0.392,0.302,0.399
				C167.573,235.866,167.595,227,167.596,227z"
          />
        </g>
      </g>
    </g>
    <g id="PASSENGER">
      <path
        d="M221.495,218.576c0.189,0.055,5.501,2.424,8.706,6.424h0.797h1.317h1.436h9.01h8.405h1.437h0.562h2.157
		c3.205-4,8.516-6.502,8.706-6.556l0.071-0.094c1.376-3.729,5.812-17.711,1.091-32.814c0,0-1.339-4.535-5.451-4.535h-0.472h-0.238
		h-1.602h-14.666h-15.299H226v0.418v-0.418h-0.217c-4.112,0-5.451,4.738-5.451,4.738c-4.721,15.104-0.285,29.06,1.091,32.788
		L221.495,218.576z"
      />
      <g>
        <path
          d="M256.866,249.741c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
			c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
			c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
			c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C257.057,251.644,256.866,249.741,256.866,249.741z"
        />
        <path
          d="M256.107,256h6.018c3.732,0,6.875-2.854,6.875-6.378v-0.839c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094
			v-2.154c0,0,0.206-4.197-3.653-4.868c0,0-0.19-0.01-2.002,0.877c-1.698,0.715-4.93,2.497-7.215,6.497h-3.427
			c-0.011,1-0.013,9.907,1.957,18.864c1.117,0.387,2.084,1.051,2.546,1.988c0.005,0.007,0.358,0.592,0.432,1.605
			c0.007,0.055,0.183,1.469-0.571,3.716L256.107,256z"
        />
        <path
          d="M228.778,256h-5.859c-3.732,0-6.919-2.854-7.919-6.378v-0.839c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094
			v-2.154c0,0,0.316-4.197,4.175-4.868c0,0,0.451-0.01,2.263,0.877c1.698,0.715,5.191,2.497,7.476,6.497h3.427
			c0.011,1,0.013,9.907-1.957,18.864c-1.117,0.387-2.084,1.051-2.546,1.988c-0.005,0.007-0.358,0.789-0.432,1.802
			C227.406,249.655,227.203,253,228.778,256z"
        />
        <g>
          <path
            d="M251.293,227h-17.516c0.011,1,0.012,9.517-1.896,18.374l7.87-0.374h13.308c0.101,0,0.201,0.392,0.302,0.399
				C251.27,235.866,251.292,227,251.293,227z"
          />
        </g>
      </g>
    </g>
    <g id="SECOND_ROW_DRIVER_SIDE">
      <path
        d="M173.596,340.27c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
		c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
		c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
		c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C173.787,342.173,173.596,340.27,173.596,340.27z"
      />
      <path
        d="M136.04,310.155c0,0,0.383-0.275,2.196,0.613c1.698,0.715,5.124,3.233,7.409,6.233h3.427
		c0.011,2,0.013,10.171-1.957,19.129c-1.117,0.387-2.084,1.183-2.546,2.121c-0.005,0.007-0.358,0.658-0.432,1.671
		c-0.009,0.065-0.248,1.698,1.045,4.725c0.242,0.621,0.536,0.354,0.87,1.354h-6.403c-3.732,0-6.648-2.325-7.648-5.849v-0.839
		c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094v-2.154C132,315.023,132.181,310.826,136.04,310.155z"
      />
      <path
        d="M168.023,317h-17.516c0.011,2,0.012,10.017-1.896,18.874l7.87,0.126h13.308c0.101,0,0.201-0.108,0.302-0.101
		C168,326.366,168.022,317,168.023,317z"
      />
      <path
        d="M180,317h-4.798h-2.342h-1.6h-0.854h-0.973c-0.011,2-0.013,10.171,1.957,19.129c1.117,0.387,2.084,1.183,2.546,2.121
		c0.005,0.007,0.358,0.658,0.432,1.671c0.009,0.065,0.248,1.698-1.045,4.725c-0.242,0.621-0.128,0.354-0.462,1.354h4.377h0.583H180
		V317z"
      />
      <path
        d="M180,273h-4.256h-0.472h-0.238h-1.602h-13.276h-1.39h-15.299h-1.247h-0.432c-4.112,0-5.451,4.43-5.451,4.43
		c-4.721,15.104-0.285,28.871,1.091,32.599l0.072-0.222c0.189,0.055,5.501,2.193,8.706,6.193h0.797h1.317h1.437h9.01h1.39h7.015
		h1.437h0.562h2.157H180V273z"
      />
    </g>
    <g id="SECOND_ROW_MIDDLE">
      <path
        d="M186.417,338.274c0.462-0.938,1.429-1.758,2.546-2.145c1.97-8.957,1.968-17.129,1.957-19.129h-1.726h-1.701h-1.857H182v29
		h5.486c-1.815-4-1.501-6.135-1.501-6.135C186.059,338.852,186.412,338.281,186.417,338.274z"
      />
      <g>
        <path
          d="M215.445,340.27c-0.066-0.907-0.256-1.458-0.256-1.458c-0.606-1.231-1.706-2.078-3.172-2.078l-22.184,0.035
			c-1.466,0-2.465,0.83-3.071,2.061c0,0-0.185,0.542-0.251,1.449c0,0-0.189,1.899,1.033,4.756c0,0,1.418,4.018,4.505,4.98
			c0,0,3.399,0.985,8.647,0.988c0.092,0.001,0.179-0.001,0.269,0c0.09,0,0.177,0.001,0.268-0.001
			c5.274-0.003,8.659-0.989,8.659-0.989c3.087-0.961,4.523-4.982,4.523-4.982C215.636,342.173,215.445,340.27,215.445,340.27z"
        />
        <g>
          <path
            d="M209.872,317h-17.516c0.011,2,0.012,10.017-1.896,18.874l7.87,0.126h13.308c0.101,0,0.201-0.108,0.302-0.101
				C209.848,326.366,209.871,317,209.872,317z"
          />
        </g>
        <path
          d="M221,317h-4.346h-0.568h-1.289h-3.427c-0.011,2-0.013,10.171,1.957,19.129c1.117,0.387,2.084,1.183,2.546,2.121
			c0.005,0.007,0.358,0.591,0.432,1.604c0,0,0.314,2.147-1.501,6.147h3.428h2.561H221V317z"
        />
        <rect x="182" y="273" width="39" height="43" />
      </g>
    </g>
    <g id="SECOND_ROW_PASSENGER_SIDE">
      <path
        d="M228.705,340.27c0.066-0.907,0.256-1.458,0.256-1.458c0.606-1.231,1.706-2.078,3.172-2.078l22.184,0.035
		c1.466,0,2.465,0.83,3.071,2.061c0,0,0.185,0.542,0.251,1.449c0,0,0.189,1.899-1.033,4.756c0,0-1.418,4.018-4.505,4.98
		c0,0-3.399,0.985-8.647,0.988c-0.092,0.001-0.179-0.001-0.269,0c-0.09,0-0.177,0.001-0.268-0.001
		c-5.274-0.003-8.659-0.989-8.659-0.989c-3.087-0.961-4.523-4.982-4.523-4.982C228.514,342.173,228.705,340.27,228.705,340.27z"
      />
      <path
        d="M266.111,310.155c0,0-0.308-0.275-2.121,0.613c-1.698,0.715-5.048,3.233-7.333,6.233h-3.427
		c-0.011,2-0.013,10.171,1.957,19.129c1.117,0.387,2.084,1.183,2.546,2.121c0.005,0.007,0.358,0.658,0.432,1.671
		c0.009,0.065,0.248,1.698-1.045,4.725c-0.242,0.621-0.536,0.354-0.87,1.354h6.403c3.732,0,6.347-2.325,7.347-5.849v-0.839
		c0-0.004,0-0.009,0-0.013s0-0.009,0-0.013v-16.014v-6.094v-2.154C270,315.023,269.97,310.826,266.111,310.155z"
      />
      <path
        d="M234.278,317h17.516c-0.011,2-0.012,10.017,1.896,18.874L245.82,336h-13.308c-0.101,0-0.201-0.108-0.302-0.101
		C234.302,326.366,234.279,317,234.278,317z"
      />
      <path
        d="M223,317h4.1h2.342h1.6h0.854h0.973c0.011,2,0.013,10.171-1.957,19.129c-1.117,0.387-2.084,1.183-2.546,2.121
		c-0.005,0.007-0.358,0.658-0.432,1.671c-0.009,0.065-0.248,1.698,1.045,4.725c0.242,0.621,0.128,0.354,0.462,1.354h-4.377h-0.583
		H223V317z"
      />
      <path
        d="M223,273h3.557h0.472h0.238h1.602h13.276h1.39h15.299h1.247h0.432c4.112,0,5.451,4.43,5.451,4.43
		c4.721,15.104,0.285,28.871-1.091,32.599l-0.072-0.222c-0.189,0.055-5.501,2.193-8.706,6.193h-0.797h-1.317h-1.437h-9.01h-1.39
		h-7.015h-1.437h-0.562h-2.157H223V273z"
      />
    </g>
  </svg>
);
