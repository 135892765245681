import { useFeatureFlag } from '../../../hooks/useFeatureFlag';

/**
 * A component to render React Router children based
 * on a feature flag
 */
export const FeatureFlaggedRoute = ({
  children,
  featureFlag,
}: {
  /**
   * A child element, typed to play will with React Router
   * (vs `ReactNode`)
   * */
  children: JSX.Element;

  /** The feature flag identifier */
  featureFlag: string;
}) => {
  const isEnabled = useFeatureFlag(featureFlag);

  if (!isEnabled) {
    return null;
  }

  return children;
};
