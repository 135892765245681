import React, { useContext } from 'react';

import { gql, useQuery } from '@apollo/client';
import { TenantConfig } from '@assured/shared-types/configs';

import { config } from '../config';

interface GetTenantConfig {
  tenantConfig: string;
}

const GET_TENANT_CONFIG = gql`
  query GetTenantConfig {
    tenantConfig
  }
`;

const TenantConfigContext = React.createContext<TenantConfig | null>(null);

// Allow overriding the tenant for quick cosmetic testing in lower environments
const isLowerEnvironment =
  process.env.REACT_APP_ENVIRONMENT === 'dev' ||
  process.env.REACT_APP_ENVIRONMENT === 'stg';
export const OVERRIDE_TENANT = isLowerEnvironment
  ? new URLSearchParams(window.location.search).get('__tenant__')
  : null;

export const TenantConfigProvider: React.FC<{}> = ({ children }) => {
  const { demoPreviewModeTenant } = config;

  const { loading, error, data } = useQuery<GetTenantConfig>(
    GET_TENANT_CONFIG,
    { skip: !!demoPreviewModeTenant },
  );
  const value = data ? (JSON.parse(data.tenantConfig) as TenantConfig) : null;

  if (OVERRIDE_TENANT && value) {
    value.tenant = OVERRIDE_TENANT;
  }

  return (
    <TenantConfigContext.Provider
      value={
        demoPreviewModeTenant
          ? ({ tenant: demoPreviewModeTenant } as TenantConfig)
          : value
      }
    >
      {isLowerEnvironment && (
        <div style={{ display: 'none' }} data-testid="tenant-config">
          {JSON.stringify({
            tenant: value?.tenant!,
            displayName: value?.displayName!,
          })}
        </div>
      )}
      {children}
    </TenantConfigContext.Provider>
  );
};

export function useTenantConfig() {
  return useContext(TenantConfigContext);
}
