import { config } from '~/src/config';
import { useTenantConfig } from '~/src/hooks';

import type { TenantConfig } from '@assured/shared-types/configs';
import { noticeError } from '@assured/telemetry/src/common';

export const useTenantConfigWithFallback = () => {
  const tenantConfig = useTenantConfig();

  // Mimic Progressive tenant config for dev and stg environments
  if (!tenantConfig) {
    if (config.environment === 'dev' || config.environment === 'stg') {
      return {
        displayName: 'Progressive',
      } as TenantConfig;
    }

    const message = 'No tenant config found';
    noticeError(new Error(message));
    // eslint-disable-next-line no-console
    console.error(message);
    return null;
  }

  return tenantConfig;
};
