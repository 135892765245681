export const OOO_SETTINGS_MODAL_QUERY_PARAM_KEY = 'ooo-settings';

export const OOO_SETTING_FOR_USER = 'self';

export const EMPTY_VALUES = {
  startAt: null,
  endAt: null,
  message: null,
  forwardToAdjusterRepCode: null,
  forwardToAdjusterRepCodeValidated: null,
};
