import { useMobileDetect } from '@assured/ui/hooks/useMobileDetect';

import useAuth from './useAuth';
import useGeolocation from './useGeolocation';
import { useHashString } from './useHashString';
import { useIntermediateValues } from './useIntermediateValues';
import useLoadMaps from './useLoadMaps';
import { useQueryStringParsed } from './useQueryString';
import { useTenantConfig } from './useTenantConfig';
import { useTenantConfigWithFallback } from './useTenantConfigWithFallback';
import { useWidgetLookup } from './useWidgetAndInlineLookup';
import useWorkflowTelemetry from './useWorkflowTelemetry';

export {
  useGeolocation,
  useAuth,
  useMobileDetect,
  useLoadMaps,
  useQueryStringParsed,
  useIntermediateValues,
  useWidgetLookup,
  useTenantConfig,
  useTenantConfigWithFallback,
  useWorkflowTelemetry,
  useHashString,
};
