import { useAuth } from '../../hooks/useAuth';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const useIsShowingHeader = () => {
  const isReducedHeaderOn = useFeatureFlag<boolean>(
    'ENG-6477-Reduced-Header',
    false,
    {
      shouldWaitForMount: true,
    },
  );

  const { user } = useAuth();

  const isReducedHeader =
    isReducedHeaderOn &&
    (user.role === 'direct_access_only' ||
      user.role === 'manager_direct_access_only');
  const isShowingHeader =
    isReducedHeader ||
    user.role !== 'direct_access_only' ||
    user.role !== 'manager_direct_access_only';

  return { isShowingHeader, isReducedHeader };
};

export default useIsShowingHeader;
