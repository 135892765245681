import { useLoadScript } from '@react-google-maps/api';

import { config } from '../config';

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];
const libraries = ['places'] as Libraries;

export default function useLoadMaps() {
  return useLoadScript({
    version: '3',
    googleMapsApiKey: config.googleMapsApiKey,
    preventGoogleFontsLoading: true,
    libraries,
  });
}
