import classNames from 'classnames';

import { IconFlatRenderer } from '../Icon/IconFlatRenderer';

export interface CheckboxProps {
  /**
   * If the checkbox is active/"checked".
   */
  isActive: boolean;

  /**
   * If the checkbox should be disabled.
   */
  isDisabled?: boolean;

  /**
   * The size of the checkbox.
   */
  size: 'sm' | 'md';

  /**
   * Classname to be applied to the checkbox.
   */
  className?: string;
}

export function Checkbox({
  size,
  isActive,
  isDisabled,
  className,
}: CheckboxProps) {
  const sizes = {
    sm: 'w-[15px] h-[15px]',
    md: 'w-[25px] h-[25px]',
  };
  const sizeClass = sizes[size];

  return (
    <div className={classNames('flex', sizeClass, className)}>
      {!isActive ? (
        <span
          className={classNames(
            'block rounded-full border border-gray-400',
            sizeClass,
            {
              'bg-cool-gray-50': !isDisabled,
              'bg-cool-gray-300': isDisabled,
            },
          )}
        />
      ) : (
        <IconFlatRenderer
          className={classNames(
            isDisabled
              ? '[&_*]:fill-cool-gray-400'
              : '[&_*]:fill-indigo-bright-600',
            sizeClass,
          )}
          iconKey="ICON_FLAT_CHECK_CIRCLE_SUBTRACT"
        />
      )}
    </div>
  );
}
