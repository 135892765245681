import { createContext, useContext } from 'react';

import { useFloating, useInteractions } from '@floating-ui/react';

import { OutOfOfficeSettingsQuery } from '../../../../generatedX/graphql';

type OutOfOfficeSettingsModalContextValue = Pick<
  ReturnType<typeof useInteractions>,
  'getReferenceProps' | 'getFloatingProps'
> &
  Pick<ReturnType<typeof useFloating>, 'floatingStyles' | 'refs'> & {
    adjusterOutOfOfficeSettings: OutOfOfficeSettingsQuery['adjusterOutOfOfficeSettings'];
    outOfOfficeActive: boolean;
    setIsOpen: (open: boolean) => void;
  };

export const OutOfOfficeSettingsModalContext =
  createContext<OutOfOfficeSettingsModalContextValue | null>(null);

export const useOutOfOfficeSettingsModalContext = () => {
  const context = useContext(OutOfOfficeSettingsModalContext);

  if (!context) {
    throw new Error(
      'OutOfOfficeSettingsModal components cannot be rendered outside an OutOfOfficeSettingsModal component',
    );
  }

  return context;
};
