import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { TenantConfig } from '@assured/shared-types/configs';

interface GetTenantConfig {
  tenantConfig: string;
}

const GET_TENANT_CONFIG = gql`
  query GetTenantConfig {
    tenantConfig
  }
`;

export const TenantConfigContext = React.createContext<TenantConfig | null>(
  null,
);

export const TenantConfigProvider: React.FC<{}> = ({ children }) => {
  const { loading, error, data } = useQuery<GetTenantConfig>(GET_TENANT_CONFIG);

  const value = data ? (JSON.parse(data.tenantConfig) as TenantConfig) : null;
  return (
    <TenantConfigContext.Provider value={value}>
      {children}
    </TenantConfigContext.Provider>
  );
};
