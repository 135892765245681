import type { TypePolicies } from '@apollo/client';

export const SIDEKICK_APOLLO_TYPE_POLICIES: TypePolicies = {
  MediaWithLinkGQL: {
    fields: {
      link: {
        merge(existing: string, incoming: string, opts) {
          // Reuse the existing source if available to prevent an image reload
          // with the new signed URL. This is safe because images themselves
          // are stable once they have been loaded. If the user uploads a new
          // image, it is a whole new Media entity.
          return existing ?? incoming;
        },
      },
    },
  },
};
