import { useLocation, useRouteMatch } from 'react-router-dom';

import { SELECTED_MESSAGE_QUERY_PARAM_KEY } from './constants';
import useConversationTimeline from './useConversationTimeline';

// Designed to prefetch a timeline if the conversation is available in the url
// and there is no search hit query param
const useTimelinePrefetch = () => {
  const match = useRouteMatch<{ id: string; conversationId: string }>(
    '/cases/:id/messaging/conversations/:conversationId',
  );
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedMessage = query.get(SELECTED_MESSAGE_QUERY_PARAM_KEY);

  const conversationId = selectedMessage
    ? ''
    : match?.params.conversationId ?? '';
  useConversationTimeline(conversationId);
};

export default useTimelinePrefetch;
