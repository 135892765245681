import {
  useOutOfOfficeSettingsByRepIdQuery,
  useOutOfOfficeSettingsModalQuery,
} from '../../../../generatedX/graphql';
import { useAuth } from '../../../../hooks/useAuth';

const useOutOfOfficeSettings = (
  repId: string,
  { skip }: { skip?: boolean } | undefined = { skip: false },
) => {
  const { user } = useAuth();
  const usingSettingForUser = !repId;
  const { data: dataForUser, loading: loadingForUser } =
    useOutOfOfficeSettingsModalQuery({
      skip: skip || !usingSettingForUser,
    });

  const userOutOfOfficeSettings = dataForUser?.adjusterOutOfOfficeSettings;

  const { data: dataForRep, loading: loadingForRep } =
    useOutOfOfficeSettingsByRepIdQuery({
      variables: {
        repId: repId || '',
      },
      skip: skip || usingSettingForUser,
    });

  const repOutOfOfficeSettings = dataForRep?.adjusterOutOfOfficeSettingsByRepId;

  return {
    loading: usingSettingForUser ? loadingForUser : loadingForRep,
    data: usingSettingForUser
      ? userOutOfOfficeSettings
      : repOutOfOfficeSettings,
    userId: usingSettingForUser ? user?.sub : repId,
    ready: usingSettingForUser ? Boolean(dataForUser) : Boolean(dataForRep),
  };
};

export default useOutOfOfficeSettings;
