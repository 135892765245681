/**
 * Detect device type.
 */
export const getMobileDetect = (userAgent: string) => {
  const isAndroid = !!userAgent.match(/Android/i);
  const isIos = !!userAgent.match(/iPhone|iPad|iPod/i);
  const isOpera = !!userAgent.match(/Opera Mini/i);
  const isWindows = !!userAgent.match(/IEMobile/i);

  const isMobile = isAndroid || isIos || isOpera || isWindows;
  const isDesktop = !isMobile;
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
  };
};
