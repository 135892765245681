import { History } from 'history';
import React, { ReactNode, useEffect } from 'react';
import useLocalSettings from '~/src/hooks/useLocalSettings';

import { Auth0Provider } from '@auth0/auth0-react';

import config from '../../config';

export type AssuredUser = {
  name?: string;
  nickname?: string;
  sub?: string;
  picture?: string;
  god?: boolean;
  knockToken?: string;
  email?: string;
  email_verified?: boolean;
  role?: string;
  tenant?: string;
};

type AssuredAuthOProviderProps = {
  history: History;
  children: ReactNode;
};
export const AssuredAuthOProvider = ({
  history,
  children,
}: AssuredAuthOProviderProps): JSX.Element => {
  // Skip Auth provider for specifically the renderer since in development,
  // it is loaded in Docker, with a non-localhost non-HTTPS docker.internal
  // URL, which causes auth0-spa to panic about insecure origin.
  const isNoAuthPath = /^\/_caseview_renderer/.test(window.location.pathname);

  // If a `connection` is set, we use it to restrict the login to a specific
  // Auth0 connection (e.g. an SSO connection only). However, "God" users
  // (e.g. Assured staff) need to be able to login using any connection, so
  // we allow bypass via a query string parameter.
  // Once a user has bypassed, we cache the value in local storage so that
  // they don't have to keep adding the query string parameter.
  const { settings, updateSettings } = useLocalSettings();
  const isCachedInternal = settings.isInternalUser;
  const isInternalQueryString = /internal=true/.test(window.location.search);
  const isInternal = isInternalQueryString || isCachedInternal;
  const connection = isInternal ? undefined : config.authentication?.connection;
  useEffect(() => {
    if (isInternalQueryString) {
      updateSettings({ isInternalUser: true });
    }
  }, [isInternalQueryString]);

  if (config.authentication && !isNoAuthPath) {
    return (
      <Auth0Provider
        domain={config.authentication.domain}
        clientId={config.authentication.clientId}
        audience={config.authentication.audience}
        connection={connection}
        redirectUri={window.location.origin}
        onRedirectCallback={(appState: any) => {
          history.replace(appState?.returnTo || window.location.pathname);
        }}
      >
        {children}
      </Auth0Provider>
    );
  }
  return <>{children}</>;
};
