export interface MediaType {
  source: string;
  lastModifiedAt?: string;
  type: string;
  ofLicensePlateOfInvolvedPartyId?: string;
  // FIXME(12-7-2019) JSON type
  exifData: string;
  feature: string;
  ofVehicleWindow?: string;
  extractedLocation?: any;
  extractedCapturedAt: string;
  details?: string;
  categoryData?: string;
  category?: string;
}

export const LOADING_MEDIA_CLASS_NAME = 'loading-media';
