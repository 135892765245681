/* eslint-disable */
import { useEffect, useState } from 'react';

export let geolocationPermissionStatus = { granted: false };

interface GeolocationState {
  loading: boolean;
  accuracy: number | null;
  altitude: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  latitude: number | null;
  longitude: number | null;
  speed: number | null;
  timestamp: number;
}
const useGeolocation = (options?: PositionOptions | boolean) => {
  const [state, setState] = useState<GeolocationState>({
    loading: true,
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: Date.now(),
  });

  let mounted = true;
  let watchId: number;

  const onEvent: PositionCallback = event => {
    geolocationPermissionStatus.granted = true;
    if (mounted) {
      setState({
        loading: false,
        accuracy: event.coords.accuracy,
        altitude: event.coords.altitude,
        altitudeAccuracy: event.coords.altitudeAccuracy,
        heading: event.coords.heading,
        latitude: event.coords.latitude,
        longitude: event.coords.longitude,
        speed: event.coords.speed,
        timestamp: event.timestamp,
      });
    }
  };
  const onEventError: PositionErrorCallback = error => {
    geolocationPermissionStatus.granted = false;
    if (mounted) {
      setState(oldState => ({ ...oldState, loading: false, error }));
    }
  };

  useEffect(() => {
    if (!options) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      onEvent,
      onEventError,
      typeof options !== 'object' ? {} : options,
    );
    watchId = navigator.geolocation.watchPosition(
      onEvent,
      onEventError,
      typeof options !== 'object' ? {} : options,
    );

    return () => {
      mounted = false;
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return state;
};

export default useGeolocation;
