/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  Avatar,
  AvatarInitials,
} from '@assured/design-system/src/components/Avatar/Avatar';
import { MinusCircleIcon } from '@heroicons/react/solid';

export const AdjusterUserAvatar = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement> & {
    name: string;
    outOfOffice?: boolean;
    size?: 'md' | 'lg';
  }
>(({ className, name, outOfOffice = false, size = 'md', ...rest }, ref) => {
  const classes = twMerge('relative', className);

  return (
    <span className={classes} {...rest} ref={ref}>
      <Avatar
        size={size}
        className={twMerge(
          'border-none',
          outOfOffice ? 'bg-[#9D9D9D]' : 'bg-[#F69785]',
          size === 'md' && outOfOffice && 'shadow-[inset_0_0_0_2px_#E3A008]',
          size === 'lg' && outOfOffice && 'shadow-[inset_0_0_0_3px_#E3A008]',
        )}
      >
        <AvatarInitials className="text-white" name={name} />
      </Avatar>
      {outOfOffice && (
        <span
          className={twMerge(
            'absolute  bg-white flex items-center justify-center rounded-full overflow-hidden',
            size === 'md'
              ? 'h-4 w-4 top-[-3px] right-[-3px]'
              : 'h-5 w-5 top-[-3px] right-[-3px]',
          )}
        >
          <MinusCircleIcon
            className={twMerge(
              'text-yellow-400',
              size === 'md' ? 'h-4 w-4' : 'h-5 w-5',
            )}
          />
        </span>
      )}
    </span>
  );
});

AdjusterUserAvatar.displayName = 'AdjusterUserAvatar';
