import classNames from 'classnames';

import { MapWidgetSpec } from '@assured/step-renderer';

import LocationMap from '../Location/LocationMap';
import {
  WidgetComponentSharedProps,
  WidgetFC,
} from '../types/widgetComponentTypes';

export const Map: WidgetFC<WidgetComponentSharedProps<MapWidgetSpec>> = ({
  widget,
  className,
}) => {
  return (
    <>
      <div
        className={classNames(
          'rounded overflow-hidden my-6 mx-auto',
          className,
        )}
        style={{
          height: 250,
          width: '100%',
          position: 'relative',
        }}
      >
        <LocationMap
          center={{ latitude: widget.latitude, longitude: widget.longitude }}
          zoom={18}
          loaded={true}
          interactive={false}
          mapTypeId={'hybrid'}
        />
      </div>
      {widget.subtitle ? (
        <div
          className={classNames(
            'text-sm font-medium text-cool-gray-500 -mt-3 mb-6',
            className,
          )}
        >
          {widget.subtitle}
        </div>
      ) : null}
    </>
  );
};
export default Map;
