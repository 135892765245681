import {
  ConversationChannelGql,
  ConversationTimelineFragment,
  useConversationTimelineQuery,
} from '../../../generatedX/graphql';
import { useSelectedMessageCursor } from './useSelectedMessageCursor';

export type Result = {
  timeline: ConversationTimelineFragment | null;
  channel: ConversationChannelGql | null;
} & Omit<ReturnType<typeof useConversationTimelineQuery>, 'data'>;

const useConversationTimeline = (conversationId: string): Result => {
  const selectedMessageCursor = useSelectedMessageCursor();

  const { data, ...rest } = useConversationTimelineQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      conversationId,
      timelineInput: {
        first: 10,
        ...(selectedMessageCursor
          ? {
              after: selectedMessageCursor,
              inclusive: true,
            }
          : {}),
      },
    },
    skip: !conversationId,
  });

  const timeline = (() => {
    if (!data) return null;
    if (data.conversation?.__typename !== 'Conversation') return null;
    if (!data.conversation.timeline) return null;
    return data.conversation.timeline;
  })();

  const channel = (() => {
    if (!data) return null;
    if (data.conversation?.__typename !== 'Conversation') return null;
    return data.conversation.channel;
  })();

  return {
    timeline,
    channel,
    ...rest,
  };
};

export default useConversationTimeline;
