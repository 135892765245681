import React, { useContext, useEffect, useState } from 'react';

export interface IParticipant {
  id: string;
}
export interface IContact {
  id: string;
  name: string;
  role: string;
  status: string;
  initials: string;
  backgroundColor: string;
}
export interface IMessage {
  id: string;
  from: string;
  message: string;
  date: Date;
  type?: 'notice';
}

export interface IEmmaRecommendation {
  label: string;
  message: string;
}
export interface IChat {
  id: string;
  visible: boolean;
  collapsed: boolean;
  emmaControlled?: boolean;
  participants: IParticipant[];
  messages: IMessage[];
  emmaRecommendations?: IEmmaRecommendation[];
  draftMessage: string;
}
interface IMessageState {
  composerVisible: boolean;
  contacts: IContact[];
  chats: { [id: string]: IChat };
}
interface IMessageContext {
  state: IMessageState;
  setState: React.Dispatch<React.SetStateAction<IMessageState>>;
}

const MessagingState: IMessageState = {
  composerVisible: false,
  contacts: [
    {
      id: 'contact_insured',
      name: 'Dan Smith',
      role: 'Primary insured, driver of Ford Fusion',
      status: 'Submitted Jan 10th 5:58 PM EST',
      initials: 'DS',
      backgroundColor: 'bg-blue-400',
    },
    {
      id: 'contact_filing_party_passenger',
      name: 'Lucy Doe',
      role: 'Passenger in Dodge Charger',
      status: 'Not yet made contact',
      initials: 'LD',
      backgroundColor: 'bg-orange-400',
    },
    {
      id: 'contact_filing_party',
      name: 'John Doe',
      role: 'Driver of Dodge Charger',
      status: 'Submitted Jan 10th 6:41 PM EST',
      initials: 'JD',
      backgroundColor: 'bg-orange-400',
    },
    {
      id: 'contact_witness',
      name: 'Jane Roe',
      role: 'Witness',
      status: 'Submitted Jan 10th 6:48 PM EST',
      initials: 'JR',
      backgroundColor: 'bg-green-400',
    },
  ],
  chats: {
    chat_demoinsured: {
      id: 'chat_demoinsured',
      visible: false,
      collapsed: false,
      emmaControlled: false,
      participants: [{ id: 'contact_insured' }],
      messages: [],
      draftMessage: '',
    },
  },
};

const MessagingContext = React.createContext({
  state: MessagingState,
  setState: (() => {}) as React.Dispatch<React.SetStateAction<IMessageState>>,
});

export const MessagingProvider: React.FC<{}> = ({ children }) => {
  const [_, setUpdatedAt] = useState<number | null>(null);
  const [messagingState, setMessagingState] = useState(MessagingState);
  return (
    <MessagingContext.Provider
      value={{
        state: messagingState,
        setState: s => {
          setMessagingState(s);
          setUpdatedAt(Date.now());
        },
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

export function useMessaging() {
  const ctx = useContext<IMessageContext>(MessagingContext);
  const { state, setState } = ctx;

  return {
    showComposer: () => {
      setState(s => ({ ...s, composerVisible: true }));
    },
    hideComposer: () => {
      setState(s => ({ ...s, composerVisible: false }));
    },
    composerVisible: state.composerVisible,
    contacts: state.contacts,
    chats: state.chats,
    showChat: (id: string) => {
      setState(s => ({
        ...s,
        chats: {
          ...s.chats,
          [id]: {
            ...s.chats[id],
            visible: true,
            collapsed: false,
          },
        },
      }));
    },
    hideChat: (id: string) => {
      setState(s => ({
        ...s,
        chats: {
          ...s.chats,
          [id]: {
            ...s.chats[id],
            visible: false,
            collapsed: false,
          },
        },
      }));
    },
    collapseChat: (id: string) => {
      setState(s => ({
        ...s,
        chats: {
          ...s.chats,
          [id]: {
            ...s.chats[id],
            visible: true,
            collapsed: true,
          },
        },
      }));
    },
    uncollapseChat: (id: string) => {
      setState(s => ({
        ...s,
        chats: {
          ...s.chats,
          [id]: {
            ...s.chats[id],
            visible: true,
            collapsed: false,
          },
        },
      }));
    },

    setDraftMessage: ({
      chat,
      draftMessage,
    }: {
      chat: string;
      draftMessage: string;
    }) => {
      setState(s => ({
        ...s,
        chats: {
          ...s.chats,
          [chat]: {
            ...s.chats[chat],
            draftMessage,
          },
        },
      }));
    },

    sendMessage: ({
      chat,
      message,
      recipients,
      type,
    }: {
      chat: string;
      message: string;
      recipients?: string[];
      type?: 'notice';
    }) => {
      simulateSend(ctx, { chat, message, type, recipients });
    },

    addContact: (contact: IContact) => {
      setState(s => ({
        ...s,
        contacts: [...s.contacts, contact],
      }));
    },

    startEmma: (id: string) => {
      setState(s => ({
        ...s,
        chats: {
          ...s.chats,
          [id]: {
            ...s.chats[id],
            emmaControlled: true,
          },
        },
      }));
      simulateSend(ctx, {
        chat: id,
        message: 'Emma joined the conversation',
        type: 'notice',
      });
    },
    stopEmma: (id: string) => {
      setState(s => ({
        ...s,
        chats: {
          ...s.chats,
          [id]: {
            ...s.chats[id],
            emmaControlled: false,
          },
        },
      }));
      simulateSend(ctx, {
        chat: id,
        message: 'Emma left the conversation',
        type: 'notice',
      });
    },

    simulateSendMessage: ({
      chat,
      message,
      from,
      type,
    }: {
      chat: string;
      message: string;
      from: string;
      type?: 'notice';
    }) => {
      simulateSend(ctx, { chat, message, type, from });
    },

    showEmmaRecommendation: ({
      chat,
      label,
      message,
    }: {
      chat: string;
      label: string;
      message: string;
    }) => {
      setState(s => ({
        ...s,
        chats: {
          ...s.chats,
          [chat]: {
            ...s.chats[chat],
            emmaRecommendations: [
              ...(s.chats[chat].emmaRecommendations || []),
              {
                label,
                message,
              },
            ],
          },
        },
      }));
    },
  };
}

export const simulatorWaitForOperator = (
  {
    desiredKey,
  }: {
    desiredKey: 'Enter' | '`' | RegExp;
  } = { desiredKey: '`' },
) => {
  return new Promise<void>(resolve => {
    const listener = (e: KeyboardEvent) => {
      e.preventDefault();
      const isMatched =
        desiredKey instanceof RegExp
          ? desiredKey.test(e.key)
          : e.key === desiredKey;
      if (isMatched) {
        window.removeEventListener('keydown', listener);
        resolve();
      }
    };
    window.addEventListener('keydown', listener);
  });
};

async function simulateSend(
  ctx: IMessageContext,
  {
    chat,
    message,
    recipients,
    type,
    from = 'self',
  }: {
    chat: string;
    message: string;
    recipients?: string[];
    type?: 'notice';
    from?: string;
  },
) {
  // Append adjuster signature
  if (type !== 'notice' && from === 'self') {
    message += ' —Tom';
  }

  if (chat === 'new' && recipients) {
    const id = Math.random().toString();
    chat = id;
    ctx.setState(s => {
      s.chats[id] = {
        id,
        visible: true,
        collapsed: false,
        participants: recipients.map(id => ({ id })),
        draftMessage: '',
        messages: [
          {
            id: Math.random().toString(),
            from,
            message,
            date: new Date(),
          },
        ],
      };
      return s;
    });
  } else {
    ctx.setState(s => {
      s.chats[chat].messages = s.chats[chat].messages.concat([
        {
          id: Math.random().toString(),
          from,
          message,
          date: new Date(),
          type,
        },
      ]);
      s.chats[chat].emmaRecommendations = [];
      return s;
    });
  }

  // Trigger other simulation responses
  if (message.indexOf('out of stock') !== -1) {
    await simulatorWaitForOperator();
    simulateSend(ctx, {
      chat,
      message:
        "That's frustrating but appreciate you letting me know. Is there any way you can get it done sooner? I need to drive up to my parents' house this weekend and need my car.",
      from: 'contact_insured',
    });
    simulateDraft(ctx, {
      chat,
      message:
        'Hi Dan, I just spoke to the body shop and was able to expedite the delivery of your part, it should now be ready for pickup this Thursday. Thank you for your patience.',
    });
  } else if (message.indexOf('for your patience') !== -1) {
    await simulatorWaitForOperator();
    simulateSend(ctx, {
      chat,
      message: "You're the best! Really appreciate it",
      from: 'contact_insured',
    });
    simulateDraft(ctx, {
      chat,
      message: 'No problem, have a great day Dan.',
    });

    await simulatorWaitForOperator();

    simulateSend(ctx, {
      chat,
      message: 'Demo: time advanced 3 hours',
      type: 'notice',
      from: 'emma',
    });

    await simulatorWaitForOperator();

    simulateSend(ctx, {
      chat,
      message:
        "Hey Emma, I realize that I won't be able to pick up the car this Thursday. Can my daughter, Sarah, pick up the car instead?",
      from: 'contact_insured',
    });
    simulateDraft(ctx, {
      chat,
      message:
        "Hey Dan, that's no problem. What's Sarah's cell phone number so I can loop her into the conversation?",
    });
  } else if (message.indexOf('phone number') !== -1) {
    await simulatorWaitForOperator();
    simulateSend(ctx, {
      chat,
      message: 'Her number is 650-565-2623',
      from: 'contact_insured',
    });
    simulateDraft(ctx, {
      chat,
      message: 'Great, thanks Dan. Be on the lookout for a group message soon',
    });
  } else if (message.indexOf('confirm your') !== -1) {
    await simulatorWaitForOperator();
    simulateSend(ctx, {
      chat,
      message: 'Hi Tom, F234892 and 12/7/92',
      from: 'contact_demo',
    });
    simulateDraft(ctx, {
      chat,
      message: "Perfect, thanks Sarah. You're all good to go for the pickup.",
    });
  } else if (message.indexOf('good to go') !== -1) {
    await simulatorWaitForOperator();
    simulateSend(ctx, {
      chat,
      message: 'Thanks for the help Tom!',
      from: 'contact_insured',
    });
    simulateDraft(ctx, {
      chat,
      message: 'No problem. Let me know if I can be of any additional help.',
    });
  } else if (from === 'self' && message.indexOf('Hi') === 0) {
    simulateDraft(ctx, {
      chat,
      message:
        'This is Tom, the adjuster on your claim 823488. Sarah, can you please confirm your drivers license number and date of birth?',
    });
  }
}

async function simulateDraft(
  ctx: IMessageContext,
  {
    chat,
    message,
  }: {
    chat: string;
    message: string;
  },
) {
  for (let i = 0; i < message.length; i++) {
    await simulatorWaitForOperator({ desiredKey: /^[a-z]$/ });
    ctx.setState(s => {
      s.chats[chat].draftMessage = message.substring(0, i + 1);
      return s;
    });
  }
  await simulatorWaitForOperator({ desiredKey: 'Enter' });
}
