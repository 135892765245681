import React from 'react';

type PlaceholderProps = { message: JSX.Element | string };
export const Placeholder = ({ message }: PlaceholderProps): JSX.Element => (
  <div className="absolute inset-0 flex items-center justify-center">
    <div className="font-bold text-gray-500 max-w-xl p-4 text-center">
      {message}
    </div>
  </div>
);
