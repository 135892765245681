import React from 'react';

import { OutOfOfficeGlobalBanner } from '../CaseView2/messaging/OutOfOfficeSettings/OutOfOfficeGlobalBanner';
import { ShowIfPermitted } from '../shared/ShowIfPermitted';
import { Header } from './Header';
import { LayoutProps } from './LayoutProps';
import { LeftNav } from './LeftNav';
import { ReducedHeader } from './ReducedHeader';
import useIsShowingHeader from './useIsShowingHeader';

const Layout: React.FC<LayoutProps> = ({
  navigation,
  subNavigation,
  hideLeftNav,
  children,
}) => {
  const { isShowingHeader, isReducedHeader } = useIsShowingHeader();

  return (
    <div className="h-screen bg-gray-50 flex overflow-hidden">
      <ShowIfPermitted
        condition={(c, u) =>
          u?.role !== 'direct_access_only' &&
          u?.role !== 'manager_direct_access_only' &&
          !hideLeftNav
        }
      >
        <LeftNav />
      </ShowIfPermitted>
      <div className="flex-1 flex flex-col overflow-hidden">
        <OutOfOfficeGlobalBanner />

        {isShowingHeader && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {isReducedHeader ? (
              <ReducedHeader />
            ) : (
              <Header navigation={navigation} subNavigation={subNavigation} />
            )}
          </>
        )}

        {/* Main content */}
        <div className="flex-1 flex items-stretch overflow-hidden">
          <main className="flex-1 overflow-y-auto">{children}</main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
