import { ZodError } from 'zod';
import { FormikErrors } from 'formik';

type NestedErrors<T> = {
  [K in keyof T]?: T[K] extends Array<infer U>
    ? Array<NestedErrors<U> | string>
    : T[K] extends object
    ? NestedErrors<T[K]>
    : string;
};

const setNestedError = <T>(
  obj: NestedErrors<T>,
  path: (string | number)[],
  message: string,
): NestedErrors<T> => {
  const [first, ...rest] = path;
  const key = first as keyof NestedErrors<T>;

  if (rest.length === 0) {
    return { ...obj, [key]: message };
  }

  const nextObj =
    (obj[key] as NestedErrors<T>) || (typeof rest[0] === 'number' ? [] : {});

  return {
    ...obj,
    [key]: setNestedError(nextObj, rest, message),
  };
};

export const zodToFormikErrors = <T>(error: ZodError): FormikErrors<T> => {
  const errors = error.issues.reduce<NestedErrors<T>>((result, issue) => {
    return setNestedError(result, issue.path, issue.message);
  }, {});

  return errors as FormikErrors<T>;
};
