/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

/**
 * Use this to redact claims before saving them as fixtures.
 */
export function redactClaim(claim) {
  // Helper function to deeply clone an object to avoid mutating the original object
  const deepClone = obj => JSON.parse(JSON.stringify(obj));

  // Helper function to redact information
  const redactInfo = obj => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        redactInfo(obj[key]); // Recurse into sub-objects
      } else {
        // Define keys that should have their string values replaced with "REDACTED"
        const redactKeys = [
          'firstName',
          'lastName',
          'middleName',
          'phoneNumber',
          'addressText',
          'line1',
          'line2',
          'city',
          'name',
          'insuredName',
        ];
        // Special case keys
        const specialCaseKeys = {
          'latitude': () => 0.0,
          'longitude': () => 0.0,
          'licensePlate': () => 'ABCD000',
          'vin': v => v?.length === 17 ? 'ABCD0000000000000' : 'REDACTED',
        };

        if (redactKeys.includes(key)) {
          obj[key] = 'REDACTED';
        }
        if (key in specialCaseKeys) {
          obj[key] = specialCaseKeys[key](obj[key]);
        }
      }
    }
  };

  // Clone the object to avoid modifying the original
  const clonedObj = deepClone(claim);
  // Redact the cloned object
  redactInfo(clonedObj);

  return clonedObj;
}
