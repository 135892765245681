const textAccepted = 'Accepted';
const textAcceptedCovered = 'Accepted (Covered)';
const textAcceptedNotCovered = 'Accepted (Not Covered)';
const textDeclined = 'Declined';
const textDeclinedCovered = 'Declined (Covered)';
const textDeclinedNotCovered = 'Declined (Not Covered)';

/**
 * A function to get "accepted" with or without "covered / not covered" text
 * @see - {@link https://linear.app/assured/issue/ENG-5302/pgr-feedback-remove-coverednot-covered-on-rental-preference-section | ENG-5302}
 */
export const getAcceptedText = ({
  isCovered,
  shouldShowIsCovered,
}: {
  isCovered: boolean;
  shouldShowIsCovered?: boolean;
}) =>
  !shouldShowIsCovered
    ? textAccepted
    : !isCovered
    ? textAcceptedNotCovered
    : textAcceptedCovered;

/**
 * A function to get "declined" with or without "covered / not covered" text
 * @see - {@link https://linear.app/assured/issue/ENG-5302/pgr-feedback-remove-coverednot-covered-on-rental-preference-section | ENG-5302}
 */
export const getDeclinedText = ({
  isCovered,
  shouldShowIsCovered,
}: {
  isCovered: boolean;
  shouldShowIsCovered?: boolean;
}) =>
  !shouldShowIsCovered
    ? textDeclined
    : !isCovered
    ? textDeclinedNotCovered
    : textDeclinedCovered;

/**
 * Joins a list of items into a single-string list, with an Oxford comma and "and" where necessary.
 */
export const listWithOxfordComma = (list: string[]) => {
  if (list.length === 1) {
    return list[0];
  }
  if (list.length === 2) {
    return `${list[0]} and ${list[1]}`;
  }
  return `${list.slice(0, -1).join(', ')}, and ${list.slice(-1)[0]}`;
};
