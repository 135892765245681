import './Modal.css';

import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Div100vh from 'react-div-100vh';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

interface ModalProps {
  id?: string;
  title: string | React.ReactNode;
  body: React.ReactNode;
  actions: {
    title: string;
    primary?: boolean;
    disabled?: boolean;
    className?: string;
    onClick: (history?: any) => void;
  }[];
  icon?: string;
  open?: boolean;
  customDismiss?: () => void;
  inlineIconTitle?: boolean;
  allowBackgroundDismiss?: boolean;
  showDismissButton?: boolean;
  fullWidth?: boolean;
  bigFormat?: boolean;
}
const Modal: React.FC<ModalProps> = ({
  id,
  title,
  body,
  actions,
  icon,
  inlineIconTitle,
  open: controlledOpen,
  customDismiss,
  allowBackgroundDismiss,
  showDismissButton,
  fullWidth,
  bigFormat,
}) => {
  const history = useHistory();
  const [display, setDisplay] = useState<boolean>(
    typeof controlledOpen === 'undefined',
  );

  const modal = useRef<HTMLElement | null>(null);
  const [scrollMode, setScrollMode] = useState(false);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof controlledOpen !== 'undefined') {
      if (controlledOpen) setDisplay(true);
      setTimeout(() => setOpen(controlledOpen), 50);
    }
  }, [controlledOpen]);

  useEffect(() => {
    if (typeof controlledOpen === 'undefined') {
      setDisplay(true);
      setTimeout(() => setOpen(true), 50);
    }
  }, [id, controlledOpen]);

  useLayoutEffect(() => {
    if (!modal.current) {
      return;
    }

    const reflow = () => {
      if (modal.current) {
        setScrollMode(modal.current.clientHeight > window.innerHeight - 100);
      }
    };
    reflow();

    try {
      const observer = new MutationObserver(() => reflow());
      observer.observe(modal.current, {
        childList: true,
        subtree: true,
      });
      window.addEventListener('resize', reflow);
      return () => {
        window.removeEventListener('resize', reflow);
        observer.disconnect();
      };
    } catch (e: any) {
      console.log(
        `MutationObserver not supported, so not monitoring for changes.`,
      );
    }
  }, [open]);

  const onDismiss = () => {
    setOpen(false);
    if (customDismiss != null) customDismiss();
  };

  const onExited = () => {
    setDisplay(false);
  };

  const content = display ? (
    <Div100vh
      style={{
        minHeight: 'Assured' in window ? '100vh' : '100rvh',
      }}
      className={classNames(
        'fixed top-0 p-4 inset-0 z-50',
        scrollMode
          ? 'h-full overflow-auto'
          : 'flex items-center justify-center sm:p-0',
      )}
      onClick={allowBackgroundDismiss ? () => onDismiss() : undefined}
    >
      <CSSTransition
        key="bg-transition"
        in={open}
        timeout={300}
        unmountOnExit
        appear
        classNames="AssuredModal-background"
      >
        <div className="transition-opacity ease-out duration-300 fixed inset-0 opacity-0">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
      </CSSTransition>

      <div
        className="absolute inset-0"
        style={{
          minHeight: '100vh',
          maxHeight: 'fit-content',
          backgroundColor: 'rgb(107 114 128 / 0.75)',
          alignContent: 'center',
        }}
      >
        <CSSTransition
          key="modal-transition"
          in={open}
          timeout={300}
          unmountOnExit
          classNames="AssuredModal"
          onExited={onExited}
          appear
        >
          <dialog
            ref={r => {
              modal.current = r;
            }}
            aria-labelledby="modal-heading"
            aria-modal="true"
            className={classNames(
              'block AssuredModal relative transition ease-out duration-300 bg-white rounded-lg px-4 pt-6 pb-5 overflow-hidden shadow-xl z-10 sm:max-w-lg sm:w-full sm:p-6 opacity-0 transform scale-95 mx-auto mt-4 mb-4',
              fullWidth ? 'w-full' : '',
            )}
            onClick={e => e.stopPropagation()}
          >
            {showDismissButton ? (
              <div className="absolute top-0 right-0 pt-2 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 text-2xl"
                  onClick={() => onDismiss()}
                >
                  ✕
                </button>
              </div>
            ) : null}
            <div>
              {icon ? (
                <div
                  className={classNames(
                    'mx-auto items-center justify-center bg-green-100',
                    inlineIconTitle
                      ? 'rounded-md inline-flex px-4 py-2'
                      : 'flex rounded-full h-12 w-12',
                  )}
                >
                  {icon === 'check' ? (
                    <svg
                      className="h-6 w-6 text-green-600"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  ) : null}
                  {inlineIconTitle ? (
                    <span className="ml-2">{title}</span>
                  ) : null}
                </div>
              ) : null}
              <div className="mt-3 text-center sm:mt-5">
                {!inlineIconTitle ? (
                  <h3
                    id="modal-heading"
                    className={classNames(
                      'font-medium text-cool-gray-900 mb-2',
                      bigFormat ? 'text-2xl leading-8' : 'text-lg leading-6',
                    )}
                  >
                    {title}
                  </h3>
                ) : null}
                <div>
                  <div
                    className={classNames(
                      'text-cool-gray-500',
                      bigFormat
                        ? 'text-lg px-6 mt-4 mb-6 leading-6'
                        : 'text-sm leading-5',
                    )}
                  >
                    {body}
                  </div>
                </div>
              </div>
            </div>
            {actions && actions.length ? (
              <div className="mt-3 flex flex-wrap justify-center sm:mt-4">
                {actions.map(
                  ({
                    title,
                    primary,
                    disabled,
                    className,
                    onClick,
                  }: {
                    title: string;
                    primary?: boolean;
                    disabled?: boolean;
                    className?: string;
                    onClick?: (x: any) => void;
                  }) => (
                    <button
                      key={title}
                      onClick={() => {
                        if (disabled) {
                          return;
                        }
                        if (!controlledOpen) onDismiss();
                        if (onClick) onClick(history);
                      }}
                      autoFocus={!!primary}
                      type="button"
                      disabled={disabled}
                      className={classNames(
                        'btn',
                        primary ? 'btn-blue' : 'btn-subtle',
                        disabled && 'btn-disabled',
                        bigFormat ? 'mb-4' : 'py-2',
                        'mt-0',
                        className,
                      )}
                      data-testid={
                        primary ? 'primaryAction' : 'secondaryAction'
                      }
                    >
                      {title}
                    </button>
                  ),
                )}
              </div>
            ) : (
              <div className="mt-3 sm:mt-4">
                <button
                  onClick={onDismiss}
                  type="button"
                  className="btn btn-blue py-2 mt-0"
                  data-testid="primaryAction"
                >
                  Got it
                </button>
              </div>
            )}
          </dialog>
        </CSSTransition>
      </div>
    </Div100vh>
  ) : null;

  const target = document.getElementById('Assured-modal-root');
  if (target) {
    return ReactDOM.createPortal(content, target);
  } else {
    return content;
  }
};
export default Modal;
