import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useAuth } from '~/src/hooks/useAuth';

import {
  ChartPieIcon,
  CogIcon,
  HomeIcon,
  PhoneIncomingIcon,
  PhotographIcon,
  ScaleIcon,
  UserGroupIcon,
  ViewGridIcon,
} from '@heroicons/react/outline';

import config from '../../config';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import logo from '../../images/icons/logo.svg';
import {
  ANALYTICS_ALLOWED_ROLES,
  ANALYTICS_DASHBOARD_ICON_VISIBLE,
  FEATURE_FLAG_ANALYTICS_DASHBOARD,
} from '../AnalyticsDashboard/constants';
import { useTenantConfig } from '~/src/hooks/useTenantConfig';

export const LeftNav = () => {
  // FIXME: Refactor to more generic permission checking, and move the user permission
  // checking to a single place rather than duplicated.
  const { user } = useAuth();
  const tenantConfig = useTenantConfig();

  // Check if the Analytics Dashboard feature flag is enabled
  const analyticsDashboardEnabled = useFeatureFlag<boolean>(
    FEATURE_FLAG_ANALYTICS_DASHBOARD,
  );

  // Check if the Analytics Dashboard icon should be visible
  const analyticsDashboardIconVisible = useFeatureFlag<boolean>(
    ANALYTICS_DASHBOARD_ICON_VISIBLE,
  );
  // Check if the user has the correct role to view the Analytics Dashboard
  const showAnalyticsDashboard =
    analyticsDashboardEnabled && ANALYTICS_ALLOWED_ROLES.includes(user?.role);

  // Determine the label for the first contact based on the tenant configuration
  const firstContactLabel = !tenantConfig?.features
    .firstContact_replaceCopyInvestigationWithFirstContact
    ? 'Investigation'
    : 'First Contact';

  // Construct the sidebar navigation based on the user's role and the enabled features
  const sidebarNavigation = getSidebarNavigation({
    firstContactLabel,
    analyticsDashboardIconVisible,
    showAnalyticsDashboard,
  });

  return (
    <div className="w-28 bg-gray-900 overflow-y-auto">
      <div className="w-full py-6 flex flex-col items-center">
        <div className="flex-shrink-0 flex items-center">
          <img className="h-8 w-auto" src={logo} alt="Workflow" />
        </div>
        <div className="flex-1 mt-6 w-full px-2 space-y-1">
          {sidebarNavigation
            .map(item => {
              return {
                ...item,
                current: window.location.pathname.startsWith(item.href),
              };
            })
            .map(item => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  item.current
                    ? 'bg-gray-800 text-white'
                    : 'text-indigo-100 hover:bg-gray-800 hover:text-white',
                  'group w-full py-3 px-1 rounded-md flex flex-col items-center text-xs font-medium',
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? 'text-white'
                      : 'text-gray-300 group-hover:text-white',
                    'h-6 w-6',
                  )}
                  aria-hidden="true"
                />
                <span className="mt-2" style={{ textAlign: 'center' }}>
                  {item.name}
                </span>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

const getSidebarNavigation = (params: {
  firstContactLabel: string;
  analyticsDashboardIconVisible: boolean;
  showAnalyticsDashboard: boolean;
}) => {
  const {
    firstContactLabel,
    analyticsDashboardIconVisible,
    showAnalyticsDashboard,
  } = params;

  const sidebar = [];

  // When the user is in a specific mode, only show the relevant sidebar items
  // If Intake and Investigation mode is enabled, only show Intake and Investigation
  if (config.useIntakeAndInvestigationMode) {
    sidebar.push({ name: 'Intake', href: '/claims', icon: HomeIcon });
    sidebar.push({ name: firstContactLabel, href: '/cases', icon: ScaleIcon });
    // If Intake only mode is enabled, only show Intake
  } else if (config.useIntakeOnlyMode) {
    sidebar.push({ name: 'Intake', href: '/claims', icon: HomeIcon });
    // If Investigation mode is enabled, only show Investigation
  } else if (config.useInvestigationMode) {
    sidebar.push({ name: firstContactLabel, href: '/cases', icon: ScaleIcon });
    // If none of the above modes are enabled, show default sidebar items
  } else {
    sidebar.push({ name: 'Claims', href: '/claims', icon: HomeIcon });
    sidebar.push({ name: firstContactLabel, href: '/cases', icon: ScaleIcon });
    sidebar.push({
      name: 'Sidekick',
      href: '/sidekick',
      icon: PhoneIncomingIcon,
    });
    sidebar.push({ name: 'CAT', href: '/cat/dashboard', icon: ViewGridIcon });
    sidebar.push({ name: 'Plugins', href: '/plugins', icon: PhotographIcon });
  }

  // If Analytics Dashboard is enabled and the user has the correct role, show the Analytics sidebar item
  if (analyticsDashboardIconVisible && showAnalyticsDashboard) {
    sidebar.push({ name: 'Analytics', href: '/analytics', icon: ChartPieIcon });
  }

  // If User Management is not hidden, show the Users sidebar item
  if (!config.hideUserManagement) {
    sidebar.push({ name: 'Users', href: '/users', icon: UserGroupIcon });
  }

  // Always show the Settings sidebar item
  sidebar.push({ name: 'Settings', href: '/settings', icon: CogIcon });

  return sidebar;
};
