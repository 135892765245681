import React, { useRef, useState } from 'react';

import {
  KnockNotificationFeed,
  NotificationFeedPopover,
  NotificationIconButton,
} from '../../services/knock';

export const Notifications = () => {
  const notificationsButtonRef = useRef<HTMLButtonElement>(null);
  const [notificationsFeedIsVisible, setNotificationsFeedIsVisible] =
    useState(false);

  return (
    <KnockNotificationFeed
      displayMode="custom"
      customRenderer={({ onNotificationClick, renderItem }) => (
        <div>
          <span className="text-gray-400 hover:text-gray-500">
            <NotificationIconButton
              ref={notificationsButtonRef}
              onClick={e =>
                setNotificationsFeedIsVisible(!notificationsFeedIsVisible)
              }
            />
          </span>
          <NotificationFeedPopover
            buttonRef={notificationsButtonRef}
            isVisible={notificationsFeedIsVisible}
            onClose={() => setNotificationsFeedIsVisible(false)}
            onNotificationClick={onNotificationClick}
            renderItem={renderItem}
          />
        </div>
      )}
    />
  );
};
