import classNames from 'classnames';

import { VehicleSeatmapWidgetSpec } from '@assured/step-renderer';

import Seatmap from '../../Seatmap';
import {
  WidgetComponentSharedProps,
  WidgetFC,
} from '../types/widgetComponentTypes';

export const SeatmapWidget: WidgetFC<
  WidgetComponentSharedProps<VehicleSeatmapWidgetSpec>
> = ({ widget, className }) => {
  return (
    <div className={classNames(className, 'mt-4 mb-4 text-center')}>
      <Seatmap
        vehicleLayout={widget.vehicle_layout}
        selected={widget.selected_seats}
        allowAnySeat
        small={true}
      />
    </div>
  );
};

export default SeatmapWidget;
