import { AdjusterConfig } from '@assured/shared-types/configs/AdjusterConfig';

/*
  This file is used to configure the Adjuster application.
  It is used to enable/disable features and set up the application according to the tenant's needs.
  The configuration is then used in the Adjuster application to enable/disable features and set up the application accordingly.
  The configuration can be overridden by the environment variable REACT_APP_SETTINGS,  which are injected using Doppler.
*/

let config = {
  whitelabel: false,
  tenant: 'Assured',
  singleClaim: false,
  usePreciseSpeedsInCollisionIq: true,
} as AdjusterConfig;

if (window.location.href.includes('perspective_view_beta=1')) {
  config = { ...config, usePerspectiveViewInIntake: true };
}
if (window.location.href.includes('perspective_view_beta_demo=1')) {
  config = {
    ...config,
    usePerspectiveViewInIntake: true,
    usePerspectiveViewDemoInIntake: true,
  };
}
if (window.location.href.includes('i18n=1')) {
  config = { ...config, useLocalizationDemoInIntake: true };
}
if (window.location.href.includes('triage=1')) {
  config = { ...config, useTriageDemoInIntake: true };
}
if (window.location.href.includes('link_investigation=1')) {
  config = { ...config, investigationLinkMode: true };
}
if (window.location.href.includes('link_repair_investigation=1')) {
  config = {
    ...config,
    useInvestigationMode: true,
    useIntakeOnlyMode: false,
    investigationLinkMode: true,
    investigationRepairCoordinationMode: true,
  };
}
if (window.location.href.includes('messaging=1')) {
  config = { ...config, enableMessaging: true };
}
if (window.location.href.includes('demo_iq=1')) {
  config = { ...config, useDemoIq: true, useDemoIqFraud: true };
}
if (window.location.href.includes('demo_longitudinal_injury=1')) {
  config = { ...config, useDemoIq: true, useLongitudinalInjuryDemo: true };
}
if (window.location.href.includes('guided_ciq_summary=1')) {
  config = { ...config, enableGuidedCollisionReconstructionSummary: true };
}
if (window.location.href.includes('fnol_to_investigation=1')) {
  config = { ...config, intakeOfferFnolToInvestigationLink: true };
}
if (window.location.href.includes('ciq_precise=1')) {
  config = { ...config, usePreciseSpeedsInCollisionIq: true };
}

// This is a way to override the config with environment variables (currently provided injected using Doppler)
if (process.env.REACT_APP_SETTINGS) {
  config = { ...config, ...JSON.parse(process.env.REACT_APP_SETTINGS) };
}

const graphqlSubscriptionsEndpoint =
  process.env.REACT_APP_GRAPHQL_SUBSCRIPTIONS_ENDPOINT;

if (graphqlSubscriptionsEndpoint) {
  config = { ...config, graphqlSubscriptionsEndpoint };
}

export default config;
