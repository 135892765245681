import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { twMerge } from 'tailwind-merge';

import { Spinner } from '@assured/design-system/src/components/Spinner';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';

import { useValidateRepIdLazyQuery } from '../../../../generatedX/graphql';
import {
  EMPTY_VALUES,
  OOO_SETTING_FOR_USER,
  OOO_SETTINGS_MODAL_QUERY_PARAM_KEY,
} from './constants';
import { useOutOfOfficeSettingsModalContext } from './OutOfOfficeSettingsContext';
import OutOfOfficeSettingsFooter from './OutOfOfficeSettingsFooter';
import OutOfOfficeSettingsForm from './OutOfOfficeSettingsForm';
import useOutOfOfficeSettings from './useOutOfOfficeSettings';

const OutOfOfficeSettingsContainer = ({
  isManager,
}: {
  isManager: boolean;
}) => {
  const location = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const isSelf =
    query.get(OOO_SETTINGS_MODAL_QUERY_PARAM_KEY) === OOO_SETTING_FOR_USER;
  const { setIsOpen } = useOutOfOfficeSettingsModalContext();
  const [targetUser, setTargetUser] = useState<'user' | 'other' | null>(
    isManager && isSelf ? 'user' : null,
  );
  const [repId, setRepId] = useState<string>('');
  const [validRepId, setValidRepId] = useState<string | 'invalid' | null>(null);

  const [validateRepId, { loading: validateRepIdLoading }] =
    useValidateRepIdLazyQuery();

  useDebounce(
    async () => {
      if (!repId) return;

      validateRepId({
        variables: {
          repId,
        },
        onCompleted: result => {
          const valid = result?.validateRepId?.valid;

          if (valid) {
            setValidRepId(repId);
          } else {
            setValidRepId('invalid' as const);
          }
        },
      });
    },
    250,
    [repId, setValidRepId],
  );

  const repIdState = (() => {
    if (!isManager || targetUser !== 'other') return 'empty';
    if (validateRepIdLoading) return 'pending';
    if (validRepId === 'invalid') return 'invalid';
    if (validRepId !== repId) return 'typing';
    return 'valid';
  })();

  const showRepIdError = repIdState === 'invalid';

  const skipQuery = (() => {
    if (!isManager) return false;
    if (targetUser === null) return true;
    if (targetUser === 'other') return repIdState !== 'valid';
    return false;
  })();

  const { data, loading, userId, ready } = useOutOfOfficeSettings(
    repIdState === 'valid' && validRepId ? validRepId : '',
    {
      skip: skipQuery,
    },
  );

  const initialValues = data
    ? {
        startAt: data.startAt ? new Date(data.startAt) : null,
        endAt: data.endAt ? new Date(data.endAt) : null,
        message: data.message || '',
        forwardToAdjusterRepCode: data.forwardToAdjusterRepCode || null,
        forwardToAdjusterRepCodeValidated: data.forwardToAdjusterRepCode
          ? true
          : null,
      }
    : EMPTY_VALUES;

  return (
    <>
      {isManager && (
        <div className="p-6 flex flex-col gap-3">
          <fieldset className="block w-full h-fit">
            <legend className="block text-gray-700 font-medium text-lg leading-5 mb-3">
              Manager only
            </legend>
            <label
              htmlFor="ooo-setfor-user"
              className="flex items-center gap-3 cursor-pointer mb-3"
            >
              <input
                id="ooo-setfor-user"
                type="radio"
                value="user"
                checked={targetUser === 'user'}
                onChange={() => setTargetUser('user' as const)}
                className="accent-indigo-600 border-gray-300 rounded text-indigo-600 shadow-sm focus:ring-indigo-500 h-4 w-4 cursor-pointer"
              />
              <span className="text-sm text-gray-700 leading-4">
                Set OOO for myself
              </span>
            </label>
            <label
              htmlFor="ooo-setfor-other"
              className="flex items-center gap-3 cursor-pointer"
            >
              <input
                id="ooo-setfor-other"
                type="radio"
                value="other"
                checked={targetUser === 'other'}
                onChange={() => setTargetUser('other' as const)}
                className="accent-indigo-600 border-gray-300 rounded text-indigo-600 shadow-sm focus:ring-indigo-500 h-4 w-4 cursor-pointer"
              />
              <span className="text-sm text-gray-700 leading-4">
                Set OOO for someone else
              </span>
            </label>
          </fieldset>
          {targetUser === 'other' && (
            <div className="relative">
              <input
                type="text"
                value={repId}
                placeholder="Enter valid rep ID"
                onChange={e => {
                  setRepId(e.target.value);
                }}
                className={twMerge(
                  'w-full border shadow-sm rounded focus:outline-none text-cool-gray-700 text-sm pl-3 pr-9 h-[48px] flex-shrink-0 min-w-[144px]',
                  showRepIdError
                    ? 'border-red-700 focus:border-red-700 focus:shadow-outline-red'
                    : 'focus:border-indigo-500 border-gray-300 focus:shadow-outline-indigo',
                )}
              />
              <div className="absolute right-2 top-3">
                {repIdState === 'pending' && (
                  <Spinner className="h-6 w-6 text-gray-400" />
                )}
                {repIdState === 'invalid' && (
                  <ExclamationCircleIcon className="h-6 w-6 text-red-600" />
                )}
                {repIdState === 'valid' && (
                  <CheckCircleIcon className="h-6 w-6 text-green-600" />
                )}
              </div>
              {repIdState === 'invalid' && (
                <div className="text-xs text-red-700 font-medium p-1">
                  Invalid rep ID
                </div>
              )}
            </div>
          )}
          {(loading || ready) && <hr className="mt-3" />}
        </div>
      )}
      {!isManager && <div className="mt-6 w-full" />}
      {loading && (
        <div
          className={classNames('flex items-center justify-center w-full', {
            'mb-4': isManager,
          })}
        >
          <Spinner className="text-gray-400" />
        </div>
      )}
      {!loading && ready && (
        <OutOfOfficeSettingsForm
          key={userId}
          initialValues={initialValues}
          targetUser={targetUser === 'other' ? validRepId : null}
        />
      )}
      {isManager && !ready && (
        <OutOfOfficeSettingsFooter
          onCancel={() => setIsOpen(false)}
          submitDisabled
          submitLoading={false}
        />
      )}
    </>
  );
};

export default OutOfOfficeSettingsContainer;
