/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';
import { useId } from 'react-aria';
import { useAuth } from '~/src/hooks/useAuth';

import { XIcon } from '@heroicons/react/solid';

import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import OutOfOfficeSettingsContainer from './OutOfOfficeSettingsContainer';
import { useOutOfOfficeSettingsModalContext } from './OutOfOfficeSettingsContext';

const OutOfOfficeSettingsModalInner = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const headingId = useId();
  const { setIsOpen } = useOutOfOfficeSettingsModalContext();
  const { user } = useAuth();
  const oooForManagersEnabled = useFeatureFlag<boolean>(
    'pro-101-ooo-for-managers',
    false,
  );

  const isManager =
    oooForManagersEnabled &&
    (user?.role === 'manager_direct_access_only' ||
      user?.role === 'administrator_read_only' ||
      user?.role === 'administrator');

  return (
    <div
      className="flex flex-col items-center justify-center h-dvh w-full"
      ref={ref}
      {...props}
    >
      <div className="flex flex-col bg-white rounded-xl w-[600px] max-h-[80dvh] shadow-lg overflow-hidden">
        <header className="flex items-center min-h-[84px] border-b pl-6 pr-4">
          <h2
            className="font-medium text-xl flex-grow text-gray-700"
            id={headingId}
          >
            Out of Office Settings
          </h2>
          <button
            className="h-[40px] w-[40px] flex items-center justify-center rounded-lg flex-shrink-0 outline-none focus-visible:ring-1 transition-colors hover:bg-gray-50 text-gray-700"
            type="button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <XIcon className="h-5 w-5" />
          </button>
        </header>
        <main
          className={classNames('flex flex-col flex-grow overflow-hidden', {
            'min-h-[256px]': !isManager,
            'min-h-[110px]': isManager,
          })}
        >
          <OutOfOfficeSettingsContainer isManager={isManager} />
        </main>
      </div>
    </div>
  );
});

export default OutOfOfficeSettingsModalInner;
