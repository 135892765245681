import { capitalize } from './capitalize';

export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O,
): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export const enumToDescription = (
  enumString?: string | null,
): string | null => {
  return enumString
    ? capitalize(enumString.split('_').join(' ').toLowerCase()) ?? null
    : null;
};
