import React from 'react';

export default function CoverageIcon({
  icon,
  className,
}: {
  icon: string;
  className?: string;
}) {
  return CoverageIconsRaw.hasOwnProperty(icon)
    ? React.cloneElement(
        CoverageIconsRaw[icon as keyof typeof CoverageIconsRaw] ??
          CoverageIconsRaw.basic_economic_loss, // fallback icon
        { className },
      )
    : null;
}
const CoverageIconsRaw: { [key: string]: JSX.Element } = {
  bodily_injury_liability: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M20.5,37.8c0,0-3.8,0.8-6.7-2.1c-2.9-2.9-2.1-6.7-2.1-6.7l17-16.9c0,0,3.8-0.8,6.7,2.1c2.9,2.9,2.1,6.7,2.1,6.7
	L20.5,37.8z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M24.2,16.6l8.7,8.7"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M16.2,24.6l8.7,8.7"
      />
      <path
        fill="currentColor"
        d="M28.5,27.8c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C27.4,27.3,27.9,27.8,28.5,27.8
	z"
      />
      <path
        fill="currentColor"
        d="M25.7,25c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1S25.1,25,25.7,25z"
      />
      <path
        fill="currentColor"
        d="M22.9,22.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C21.7,21.6,22.3,22.1,22.9,22.1
	z"
      />
      <path
        fill="currentColor"
        d="M24.6,28.9c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1S23.9,28.9,24.6,28.9z"
      />
      <path
        fill="currentColor"
        d="M21.7,26.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C20.6,25.6,21.1,26.1,21.7,26.1
	z"
      />
    </svg>
  ),
  property_damage_liability: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M26.8,39.6H14.1c-2.5,0-4.6-2.1-4.6-4.6V22.4l10.9-9.2l10.9,9.2V35C31.4,37.5,29.3,39.6,26.8,39.6z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M17.6,39.6v-8h5.8v8"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M25.9,16.9l1.1-3.8l1.8,3.3l5.4-1.9l-2.1,4.9l4.9,2.1l-5.5,3.9"
      />
    </svg>
  ),
  medical_payments: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M16.2,13h-3.1v7.3c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2V13h-3.1"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M18.3,25.5v3.9c0,4.2,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-4.9"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M33.3,24.4c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6C29.7,22.8,31.3,24.4,33.3,24.4z"
      />
    </svg>
  ),
  uninsured_motorist: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <style type="text/css">
        {`.stxx0{clip-path:url(#SVGID_2_);}
	.stxx1{fill:none;stroke:currentColor;stroke-width:1.8286;stroke-miterlimit:10;}
	.stxx2{fill:currentColor;}`}
      </style>
      <g>
        <defs>
          <rect id="SVGID_1_" x="9" y="13.9" width="32" height="21.9" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
        </clipPath>
        <g className="stxx0">
          <path className="stxx1" d="M31.5,20.9l4.6,4.5" />
          <path className="stxx1" d="M31.5,25.5l4.6-4.5" />
          <path
            className="stxx2"
            d="M21.5,23c0.1-1.6-1.3-2.9-3-2.9c-1.7,0-3.1,1.3-2.9,2.9c0,0.3,0.3,2,0.3,2c0.2,1.4,1.2,2.6,2.6,2.6
			c1.4,0,2.4-1.2,2.6-2.6C21.2,25,21.5,23.4,21.5,23z"
          />
          <path
            className="stxx2"
            d="M25.9,35H11.2l0.2-3.2c0.2-1.3,1.2-2,2.3-2.4l4.7-0.7l4.7,0.7c1.3,0.2,2.3,1,2.4,2.4L25.9,35z"
          />
          <path
            className="stxx1"
            d="M33.9,29.5c3.5,0,6.3-2.8,6.3-6.3c0-3.5-2.8-6.3-6.3-6.3c-3.5,0-6.3,2.8-6.3,6.3
			C27.6,26.7,30.4,29.5,33.9,29.5z"
          />
          <path
            className="stxx1"
            d="M35.9,28.8v1.3c0,2.7-2,4.9-4.5,4.9H14.2c-2.5,0-4.5-2.2-4.5-4.9V19.5c0-2.7,2-4.9,4.5-4.9h17.1
			c1.8,0,3.4,1.2,4.1,2.9"
          />
        </g>
      </g>
    </svg>
  ),
  collision: (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7 32.1001H26.7"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
      />
      <path
        d="M15.5 35.1998C17.3 35.1998 18.7 33.7998 18.7 31.9998C18.7 30.1998 17.3 28.7998 15.5 28.7998C13.7 28.7998 12.3 30.1998 12.3 31.9998C12.3 33.7998 13.7 35.1998 15.5 35.1998Z"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
      />
      <path
        d="M29.8 35.1998C31.6 35.1998 33 33.7998 33 31.9998C33 30.1998 31.6 28.7998 29.8 28.7998C28 28.7998 26.6 30.1998 26.6 31.9998C26.6 33.7998 28.1 35.1998 29.8 35.1998Z"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
      />
      <path
        d="M29.2 23.8002H25.8C25.1 23.8002 24.5 23.1002 24.7 22.4002L25.7 18.7002"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
      />
      <path
        d="M12.3 32.0998H9.29998L7.09998 28.8998C7.09998 28.8998 9.59998 23.9998 13.6 23.9998C13.6 23.9998 14.2 18.2998 21.9 18.2998C29.6 18.2998 29.6 23.9998 29.6 23.9998C35 23.9998 37.1 26.2998 38 28.3998C38.6 29.8998 37.6 31.5998 35.9 31.5998H32.8"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
      />
      <path
        d="M15.3 20.6001H16.8C17.5 20.6001 18.1 21.2001 18.1 21.9001V22.5001C18.1 23.2001 17.5 23.8001 16.8 23.8001H13.6"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
      />
      <path
        d="M30.8 21.2998V17.4998L33.4 19.9998L37.9 16.7998L37.3 21.8998L42.4 22.4998L39.8 25.6998"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  collision_policy: (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style type="text/css">{`.adjustColl {transform: scale(1.2) translate(-10%, -10%);}`}</style>
      <path
        d="M18.7 32.1001H26.7"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        className="adjustColl"
      />
      <path
        d="M15.5 35.1998C17.3 35.1998 18.7 33.7998 18.7 31.9998C18.7 30.1998 17.3 28.7998 15.5 28.7998C13.7 28.7998 12.3 30.1998 12.3 31.9998C12.3 33.7998 13.7 35.1998 15.5 35.1998Z"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        className="adjustColl"
      />
      <path
        d="M29.8 35.1998C31.6 35.1998 33 33.7998 33 31.9998C33 30.1998 31.6 28.7998 29.8 28.7998C28 28.7998 26.6 30.1998 26.6 31.9998C26.6 33.7998 28.1 35.1998 29.8 35.1998Z"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        className="adjustColl"
      />
      <path
        d="M29.2 23.8002H25.8C25.1 23.8002 24.5 23.1002 24.7 22.4002L25.7 18.7002"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        className="adjustColl"
      />
      <path
        d="M12.3 32.0998H9.29998L7.09998 28.8998C7.09998 28.8998 9.59998 23.9998 13.6 23.9998C13.6 23.9998 14.2 18.2998 21.9 18.2998C29.6 18.2998 29.6 23.9998 29.6 23.9998C35 23.9998 37.1 26.2998 38 28.3998C38.6 29.8998 37.6 31.5998 35.9 31.5998H32.8"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        className="adjustColl"
      />
      <path
        d="M15.3 20.6001H16.8C17.5 20.6001 18.1 21.2001 18.1 21.9001V22.5001C18.1 23.2001 17.5 23.8001 16.8 23.8001H13.6"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        className="adjustColl"
      />
      <path
        d="M30.8 21.2998V17.4998L33.4 19.9998L37.9 16.7998L37.3 21.8998L42.4 22.4998L39.8 25.6998"
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        className="adjustColl"
      />
    </svg>
  ),
  roadside_assistance: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M27.4,27.2H10.2V31l4,1.3"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M35.7,32.6h1c1.6,0,2.8-1.4,2.6-3l-1-11.6h-9.2l-0.7,9.3h-0.7l-6.2-13.1h-2l2,12.6"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M20.7,32.3H29"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M17.4,35.6c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3C14.1,34.2,15.6,35.6,17.4,35.6
	z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M32.3,35.6c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3C29,34.2,30.5,35.6,32.3,35.6z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M38.9,24.4h-5v-6.6"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M17.5,14v3.4c0,1.7-1.8,2.8-3.3,1.9"
      />
    </svg>
  ),
  rental_car: (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3539 18.6846H12.2308C11.4001 18.6846 10.7539 19.3307 10.7539 20.1615C10.7539 20.9923 11.4001 21.6384 12.2308 21.6384H13.3385"
        stroke="currentColor"
        strokeWidth="1.70114"
        strokeMiterlimit="10"
      />
      <path
        d="M15.6462 29.2073H13.4308C12.7846 29.2073 12.2308 28.6535 12.2308 28.0073V27.7304C12.2308 26.9919 12.8769 26.3458 13.6154 26.5304L15.8308 26.8073C16.4769 26.8996 16.9385 27.3612 16.9385 28.0073C16.8462 28.5612 16.2923 29.2073 15.6462 29.2073Z"
        fill="currentColor"
      />
      <path
        d="M18.6 27.731H27.6461"
        stroke="currentColor"
        strokeWidth="1.70114"
        strokeMiterlimit="10"
      />
      <path
        d="M18.0461 17.4844H28.5692L29.6769 22.1921H17.0307L18.0461 17.4844Z"
        stroke="currentColor"
        strokeWidth="1.70114"
        strokeMiterlimit="10"
      />
      <path
        d="M35.8615 33.3615V34.3769C35.8615 34.4692 35.7692 34.6538 35.5846 34.6538H33.0923C32.9077 34.6538 32.8154 34.4692 32.8154 34.4692L32.6308 33.3615C31.2462 33.3615 30.1385 32.4385 29.8615 31.1462H14.3538L13.8 34.3769C13.8 34.4692 13.7077 34.6538 13.5231 34.6538H11.0308C10.9385 34.6538 10.7538 34.5615 10.7538 34.3769V28.1923C10.7538 27.0846 11.1231 26.0692 11.7692 25.2385L14.1692 22.0077L15.7385 17.2077C16.2 15.9154 17.2154 14.9923 18.5077 14.7154C19.7077 14.5308 21.3692 14.3462 23.3077 14.3462C24.7846 14.3462 26.3538 14.5308 28.0154 14.8077C28.8462 14.9923 29.6769 15.4538 30.2308 16.1923C30.5077 15.6385 31.0615 15.1769 31.6154 14.9C30.7846 13.8846 29.6769 13.1462 28.3846 12.9615C26.6308 12.5923 24.9692 12.5 23.4 12.5C21.3692 12.5 19.5231 12.7769 18.3231 12.9615C16.3846 13.3308 14.7231 14.7154 14.0769 16.6538L12.6 21.0846L10.3846 24.0385C9.46154 25.2385 9 26.6231 9 28.1V34.3769C9 35.5769 9.92308 36.5 11.1231 36.5H13.6154C14.6308 36.5 15.4615 35.7615 15.7385 34.7462L16.0154 32.9923H30.7846L31.0615 34.7462C31.2462 35.7615 32.1692 36.5 33.1846 36.5H35.6769C36.8769 36.5 37.8 35.5769 37.8 34.3769V33.3615H35.8615Z"
        fill="currentColor"
      />
      <path
        d="M39 29.8541H33.0923V27.1771C33.0923 26.3464 33.4615 25.5156 34.2 24.9618L36.0461 23.5771L37.8923 24.9618C38.6307 25.5156 39 26.2541 39 27.1771V29.8541Z"
        stroke="currentColor"
        strokeWidth="1.70114"
        strokeMiterlimit="10"
      />
      <path
        d="M33.0923 17.4844H39V20.1613C39 20.9921 38.6307 21.8228 37.8923 22.3767L36.0461 23.7613L34.2 22.3767C33.4615 21.8228 33.0923 21.0844 33.0923 20.1613V17.4844Z"
        stroke="currentColor"
        strokeWidth="1.70114"
        strokeMiterlimit="10"
      />
      <path
        d="M31.7078 29.8535H40.2924"
        stroke="currentColor"
        strokeWidth="1.70114"
        strokeMiterlimit="10"
      />
      <path
        d="M31.7078 17.4844H40.2924"
        stroke="currentColor"
        strokeWidth="1.70114"
        strokeMiterlimit="10"
      />
      <path
        d="M33.0923 27.9147L36.0461 27.3608L39 27.9147"
        stroke="currentColor"
        strokeWidth="1.70114"
        strokeMiterlimit="10"
      />
      <path
        d="M33.0923 27.7306L36.0461 27.0845L39 27.7306L39.0923 29.2076H33.0923V27.7306Z"
        fill="currentColor"
      />
    </svg>
  ),
  uninsured_motorist_damage: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <style type="text/css">{`.st0{fill:currentColor;}`}</style>
      <g>
        <rect x="14.1" y="26.2" className="st0" width="4.9" height="1.9" />
        <rect x="11.3" y="30.1" className="st0" width="8.4" height="1.9" />
        <rect x="10.6" y="22.3" className="st0" width="11.9" height="1.9" />
        <path
          className="st0"
          d="M46.6,27.3c-2.1,0-3.9,1.5-4.3,3.5h-7.1c-0.4-2-2.2-3.5-4.3-3.5s-3.9,1.5-4.3,3.5h-1.9l-1.8-2.7
		c2.6-4.4,5.1-4.4,6.8-4.6h2.7c1.3,0,2.3-1.1,2.3-2.4v-0.8c0-1-0.6-1.8-1.4-2.2c1.1-0.6,2.7-1,4.7-1c1.2,0,2.2,0.2,3,0.4l-0.8,3.2
		c-0.2,0.6,0,1.3,0.4,1.9c0.4,0.5,1.1,0.9,1.7,0.9c4.7,0,5.9,0.1,7.4,0.4c0-0.7-0.1-1.3-0.1-2c-0.7-0.1-1.5-0.2-2.3-0.3
		c-0.4-2-2-6.4-9.2-6.4c-7.3,0-9.3,4.5-9.9,6.4c-4.4,0.6-7,5.8-7.1,6l-0.2,0.5l3,4.5h3c0.4,2,2.2,3.5,4.3,3.5s3.9-1.5,4.3-3.5h7.1
		c0.4,2,2.2,3.5,4.3,3.5c0.1,0,0.1,0,0.2,0c0.4-0.7,0.7-1.4,1-2.2c-0.4,0.2-0.7,0.3-1.2,0.3c-1.4,0-2.6-1.2-2.6-2.6
		c0-1.4,1.1-2.6,2.6-2.6c1,0,1.9,0.6,2.3,1.4c0.2-0.8,0.3-1.6,0.5-2.4C48.6,27.7,47.6,27.3,46.6,27.3z M42.7,18.4
		c1.6,1,2.2,2.3,2.5,3.3h-3c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.2L42.7,18.4z M31,19.9h1.3c0.2,0,0.5,0.2,0.5,0.5v0.8
		c0,0.3-0.2,0.5-0.5,0.5H30C30.2,21.1,30.5,20.5,31,19.9z M30.9,34.4c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.1-2.6,2.6-2.6s2.6,1.2,2.6,2.6
		C33.4,33.2,32.3,34.4,30.9,34.4z"
        />
      </g>
    </svg>
  ),
  comprehensive: (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style type="text/css">{`.adjustCompStandard {transform: scale(0.9) translate(5%, 10%);}`}</style>
      <path
        d="M24.4945 10.0394C28.3963 11.5852 32.782 12.6744 38.0333 12.4787V23.2411C38.0333 29.9775 34.6233 34.2498 31.1655 36.874C29.4306 38.1907 27.6905 39.0847 26.3825 39.6491C25.7298 39.9308 25.1882 40.1289 24.8134 40.2556C24.6901 40.2973 24.5849 40.3312 24.4999 40.3578C24.415 40.3312 24.3098 40.2973 24.1864 40.2556C23.8117 40.1289 23.2701 39.9308 22.6173 39.6491C21.3094 39.0847 19.5693 38.1907 17.8343 36.874C14.3766 34.2498 10.9666 29.9775 10.9666 23.2411V12.4585C16.1957 12.5619 20.4764 11.583 24.4945 10.0394Z"
        stroke="currentColor"
        strokeWidth="1.93333"
        className="adjustCompStandard"
      />
      <path
        d="M24.4518 22.1816L24.2718 23.3016H25.4062H28.1851L24.0484 28.4725L24.6748 24.9647L24.8777 23.8281H23.7232H20.8148L25.035 18.5529L24.4518 22.1816Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.93333"
        className="adjustCompStandard"
      />
    </svg>
  ),
  comprehensive_policy: (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style type="text/css">{`.adjustComp {transform: scale(0.9) translate(0, 20%);}`}</style>
      <path
        d="M24.4945 10.0394C28.3963 11.5852 32.782 12.6744 38.0333 12.4787V23.2411C38.0333 29.9775 34.6233 34.2498 31.1655 36.874C29.4306 38.1907 27.6905 39.0847 26.3825 39.6491C25.7298 39.9308 25.1882 40.1289 24.8134 40.2556C24.6901 40.2973 24.5849 40.3312 24.4999 40.3578C24.415 40.3312 24.3098 40.2973 24.1864 40.2556C23.8117 40.1289 23.2701 39.9308 22.6173 39.6491C21.3094 39.0847 19.5693 38.1907 17.8343 36.874C14.3766 34.2498 10.9666 29.9775 10.9666 23.2411V12.4585C16.1957 12.5619 20.4764 11.583 24.4945 10.0394Z"
        stroke="currentColor"
        strokeWidth="1.93333"
        className="adjustComp"
      />
      <path
        d="M24.4518 22.1816L24.2718 23.3016H25.4062H28.1851L24.0484 28.4725L24.6748 24.9647L24.8777 23.8281H23.7232H20.8148L25.035 18.5529L24.4518 22.1816Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.93333"
        className="adjustComp"
      />
    </svg>
  ),
  basic_economic_loss: (
    <svg x="0px" y="0px" viewBox="0 0 50.98 42.05">
      <defs>
        <style>{`.cls-1,.cls-2{fill:none;stroke:currentColor;stroke-width:2px;}.cls-1{stroke-miterlimit:10;}.cls-2{stroke-linejoin:round;}`}</style>
      </defs>
      <g>
        <path
          className="cls-1"
          d="M35.61,37.8H16.48l1.16-3.3A5.62,5.62,0,0,1,23,30.73h6.19a5.62,5.62,0,0,1,5.31,3.77Z"
        />
        <path className="cls-2" d="M18.09,24.72a5.1,5.1,0,1,1-10.19,0Z" />
        <path className="cls-2" d="M34,17.19a5.1,5.1,0,0,0,10.19,0Z" />
        <polyline className="cls-2" points="7.9 24.72 13.27 15.6 18.09 24.72" />
        <polyline className="cls-2" points="44.19 17.19 38.83 8.07 34 17.19" />
        <path className="cls-1" d="M11.85,14.81c7-1.84,7.83-8.31,12.41-5.13" />
        <path className="cls-1" d="M41.09,7c-7,1.88-10.92-3.33-13.32,1.7" />
        <path
          className="cls-1"
          d="M24.53,30.35V7.07a1.51,1.51,0,0,1,1.52-1.51h0a1.51,1.51,0,0,1,1.51,1.51V30.35"
        />
      </g>
    </svg>
  ),
  pip: (
    <svg x="0px" y="0px" viewBox="0 0 50 50" fill="none">
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M16.2,13h-3.1v7.3c0,2.9,2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2V13h-3.1"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M18.3,25.5v3.9c0,4.2,3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5v-4.9"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.8429"
        strokeMiterlimit="10"
        d="M33.3,24.4c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6C29.7,22.8,31.3,24.4,33.3,24.4z"
      />
    </svg>
  ),
  two_car_collision: (
    <svg
      width="31"
      height="26"
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_84_1116)">
        <path
          d="M2.78704 20.7515H1.19057"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.19057 10.6118H5.8861L8.47803 13.2037H12.1674V20.7515H9.63448"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.8447 20.7516H15.9265V18.5943C15.9265 16.6705 17.1688 14.9667 19.0014 14.3791L22.7739 13.1689L26.7987 8.7793H29.0955"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.0953 20.7515H27.692"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.2081 24.3093C8.10045 24.3093 9.6345 22.7753 9.6345 20.8829C9.6345 18.9906 8.10045 17.4565 6.2081 17.4565C4.31576 17.4565 2.78171 18.9906 2.78171 20.8829C2.78171 22.7753 4.31576 24.3093 6.2081 24.3093Z"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.271 24.3093C26.1634 24.3093 27.6974 22.7753 27.6974 20.8829C27.6974 18.9906 26.1634 17.4565 24.271 17.4565C22.3787 17.4565 20.8446 18.9906 20.8446 20.8829C20.8446 22.7753 22.3787 24.3093 24.271 24.3093Z"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9899 9.58705C17.3728 8.6855 16.3344 8.09252 15.1592 8.08716H15.1458C13.9625 8.08716 12.9215 8.68014 12.299 9.58705"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1591 8.08708V1.69043"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3115 6.26514L17.9898 9.58689"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2988 9.58689L8.97707 6.26514"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.20805 20.8831V20.9528"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2899 20.8831V20.9528"
          stroke="currentColor"
          strokeWidth="1.78877"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_84_1116">
          <rect y="0.5" width="30.9524" height="25" rx="12.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
};

CoverageIconsRaw.residual_bodily_injury_liability =
  CoverageIconsRaw.bodily_injury_liability;
CoverageIconsRaw.collision_broad = CoverageIconsRaw.collision;
CoverageIconsRaw.collision_broad = CoverageIconsRaw.collision;
CoverageIconsRaw.collision_limited = CoverageIconsRaw.collision;
CoverageIconsRaw.underinsured_or_uninsured_motorist =
  CoverageIconsRaw.uninsured_motorist;
CoverageIconsRaw.underinsured_or_uninsured_motorist_property_damage =
  CoverageIconsRaw.uninsured_motorist_damage;
CoverageIconsRaw.underinsured_motorist = CoverageIconsRaw.uninsured_motorist;
CoverageIconsRaw.uninsured_or_underinsured_motorist =
  CoverageIconsRaw.uninsured_motorist;
CoverageIconsRaw.uninsured_motorist_property_damage =
  CoverageIconsRaw.uninsured_motorist_damage;
CoverageIconsRaw.underinsured_motorist_property_damage =
  CoverageIconsRaw.uninsured_motorist_damage;
CoverageIconsRaw.uninsured_or_underinsured__property_damage_per_policy_coverage =
  CoverageIconsRaw.uninsured_motorist_damage;
CoverageIconsRaw.uninsured_or_underinsured_motorist_property_damage =
  CoverageIconsRaw.uninsured_motorist_damage;
CoverageIconsRaw.uninsured_motorist_property_damage_per_policy_coverage =
  CoverageIconsRaw.uninsured_motorist_damage;
CoverageIconsRaw['loan/lease_payoff_coverage'] =
  CoverageIconsRaw.basic_economic_loss;
